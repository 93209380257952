import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import { Configs } from "../../Toolbox";

import RestDataSource from "../../components/webservices/RestDataSource";

import ReactHtmlParser from "react-html-parser";

import ".././../scss/views/RGPD.scss";

import { Parallax, ParallaxProvider } from "react-scroll-parallax";

class RGPD extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: null,
      data: null,
    };

    this.webserver = new RestDataSource(); // para comunicação com a API (REST)
    this.restURI = `rest/gdpr/content`; // para utilização nos pedidos de dados à API / URI do pedido

    // p utilização nas acções de tradução
    this.t = this.props.t;
    this.i18n = null;

    this.LogicLatch = false; // TRINCO LÓGICO - FALSE aberto | TRUE fechado
  }

  /**
   * @description
   * Para inicialização dos componentes e afins após o seu carregamento no DOM
   */
  componentDidMount() {
    window.sessionStorage.removeItem("stickyBFM");
    this.t = this.props.t; // p utilização nas acções de tradução
    this.i18n = this.props.i18n;

    let { lang } = this.state;
    lang = this.i18n.language;

    this.setState({ lang });

    const requestURI = `${this.i18n.language}/${this.restURI}`;

    this.logicLatch = true;
    this.getDataFromAPI(requestURI);
  }

  /***
   * @description
   *
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    // verifica se o idioma actual é igual ao idioma pré actualização
    // e actualiza o mesmo se assim se verificar necessário, i.e., se
    // os idiomas não coincidirem

    /*  let { lang } = this.state; */
    let { lang } = prevState;
    const requestURI = `${this.i18n.language}/${this.restURI}`;

    //novo idioma seleccionado, actualiza-o
    if (lang !== this.i18n.language) {
      lang = this.i18n.language;

      this.setState({ lang }, () => {
        if (this.logicLatch === false) {
          // fecha o trinco para efeitos de acesso ao pedido de
          // novos conteúdos à API
          this.logicLatch = true;
          // executa pedido
          this.getDataFromAPI(requestURI);
        }
      });
    }
  }

  /**
   * @description
   * Método responsável por usar o pedido de dados à API... a usar nas life cycle
   * componentDidMount e/ou componentDidUpdate
   */
  getDataFromAPI = (requestURL) => {
    this.webserver.getData(requestURL, (result, xhr) => {
      this.logicLatch = false;

      // trata dados retornados
      if (xhr.status === 200 && "data" in xhr && typeof xhr.data === "object") {
        // actualizar STATE var
        let { data } = this.state;
        data = result;

        this.setState({ data });
      }
    });
  };

  /** Conteudo da politica de privacidade**/
  makePolicy = () => {
    let policy = null;
    /*    console.log(this.state.data);*/
    if (this.state.data !== null && this.state.data.rgpd) {
      policy = this.state.data.rgpd.map((val, key) => {
      const descriptions = val.description.split('<div id="separator"></div>');
        return (
          <div className="politica_privacidade" key={val.id}>
            <div className="intro_rgpd">
              <h1 className="title_1">{ReactHtmlParser(val.title)}</h1>
              <p className="text">{ReactHtmlParser(val.subTitle)} </p>
              <div className="conteudo">{ReactHtmlParser(descriptions[0])} </div>
              <ParallaxProvider>
                <Parallax className="custom-class-1" x={[0, 100]} tagOuter="figure">
                  <p className="words words_cookies">
                    {this.props.t("views").footer.word_cookies}
                  </p>
                </Parallax>
              </ParallaxProvider>
              <div className="conteudo">{ReactHtmlParser(descriptions[1])} </div>
            </div>
          </div>
        );
      });
    }
    return policy;
  };

  render() {
    return (
      <div className="body_rgpd">
        <div className="bg_gray"></div>
        <div className="bg_words">
          <p className="words_1">
            {this.props.t("views").footer.value_privacy}
          </p>
          <ParallaxProvider>
            <Parallax className="custom-class" x={[0, 150]} tagOuter="figure">
              <p className="words">
                {this.props.t("views").footer.word_privacy}
              </p>
            </Parallax>
          </ParallaxProvider>
        </div>

        {this.state.data !== undefined ? this.makePolicy() : null}
      </div>
    );
  }
}

export default withTranslation()(RGPD);
