import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import $ from "jquery";

import RestDataSource from "../../components/webservices/RestDataSource";
import {
  extractElementsFromArrayByIsType,
  insertMediaWithAppAbsPath,
  Configs,
} from "../../Toolbox";

import ReactHtmlParser from "react-html-parser";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../../assets/plugins/owl_carousel/customize_owlcarousel.css";

import "../../scss/ProductDetailView.scss";

import ShareSocialMedia from "../ShareSocialMedia";

import { Parallax, ParallaxProvider } from "react-scroll-parallax";

/**
 * View: Produto em detalhe
 *
 **/
class ProductDetailView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: null,
      data: {},
    };

    this.t = this.props.t; //tradutor
    this.i18n = null;

    this.logicLatch = false; //TRINCO LÓGICO - FALSE aberto | TRUE fechado

    this.optionId = undefined; //ID da CATEGORIA dos artigos
    this.optionSlug = undefined; //respectivo slug

    this.view = this.props.location.pathname.substring(1).split("/")[1];

    if (
      this.props.match &&
      this.props.match.params &&
      "optionId" in this.props.match.params &&
      "optionSlug" in this.props.match.params
    ) {
      this.optionId = this.props.match.params.optionId;
      this.optionSlug = this.props.match.params.optionSlug;
      if (
        "familyId" in this.props.match.params &&
        "familySlug" in this.props.match.params
      ) {
        //  this.optionId = this.props.match.params.optionId
        this.optionId = this.props.match.params.familyId;
        this.familySlug = this.props.match.params.familySlug;
      }
    }

    this.webserver = new RestDataSource(); // para comunicação com a API (REST)

    if (this.view === this.t("menu").product_detalhe[1]) {
      this.restURI = `rest/products/content/fid`; // para utilização nos pedidos de dados à API / URI do pedido
    } else {
      this.restURI = `rest/products/content/fc1`; // para utilização nos pedidos de dados à API / URI do pedido
    }
  }

  /**
   * @description
   * Para inicialização dos componentes e afins após o seu carregamento no DOM
   */
  componentDidMount() {
    window.sessionStorage.removeItem("stickyBFM");
    this.t = this.props.t; // p utilização nas acções de tradução
    this.i18n = this.props.i18n;

    let { lang } = this.state;
    lang = this.i18n.language;

    setTimeout(() => {
      window.scrollTo(0, 44);
    }, 500);

    this.setState({ lang });

    // para utilização de JQUERY (OWL)
    window.jQuery = $;

    require("owl.carousel");

    this.logicLatch = true;
    this.getDataFromAPI(this.makeURI());
  }

  /**
   * @description
   * Verifica se existem alterações nas props e/ou state e executa
   * as necessárias alterações em conformidade, exemplo, quando se
   * altera o idioma do site poderá ser necessário obter novos termos
   * no novo idioma para preenchimento do layout...
   * caso se verifique que os mesmos já existam dever-se-à evitar a
   * utilização deste recurso, isto porque, poderá estar a criar um
   * conjunto de pedidos desnecessários e que eventualmente tornarão
   * esta solução mais lenta
   *
   * @param {object} prevProps
   * @param {object} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    let { lang } = prevState;

    if (lang !== this.i18n.language) {
      lang = this.i18n.language;

      this.setState({ lang }, () => {
        //idioma alterado, obtem os dados da API no novo idioma
        if (this.logicLatch === false) {
          //fecha o trinco para efeitos de acesso ao pedido de
          //novos conteúdos à API
          this.logicLatch = true;
          //executa o pedido
          this.getDataFromAPI(this.makeURI()); //(requestURI);
        }
      });
    }
  }

  makeURI() {
    let requestURI = `${this.i18n.language}/${this.restURI}`;

    // verifica se existe optionId e optionSlug e acrescenta-os ao URI
    if (this.optionId !== undefined && this.optionSlug !== undefined) {
      requestURI += `${this.optionId}`;
    }

    return requestURI;
  }

  getDataFromAPI = (requestURL) => {
    this.webserver.getData(requestURL, (result, xhr) => {
      this.logicLatch = false;

      if (xhr.status === 200 && "data" in xhr && typeof xhr.data === "object") {
        let { data } = this.state;
        data = result;

        this.setState({ data }, () => {
          // cria o corpo da página
          this.setupBodyContent();
        });
      }
    });
  };

  /**
   * @description
   * Para inicialização de elementos OWL do corpo da página
   */
  setupBodyContent = () => {
    if (
      this.state.data.productsList !== null &&
      this.state.data.productsList !== undefined
    ) {
      // incia área de destaque de projectos
      $(".product_slideshow").owlCarousel({
        autoplay: false,
        loop: true,
        margin: 0,
        responsiveClass: true,
        responsive: {
          0: {
            items: 1,
          },
          992: {
            items: 1,
          },
          1200: {
            items: 1,
          },
        },
        dots: true,
      });
    }
  };

  /**
   * Página: Estufas
   * Slideshow com as imagens das estufas c/ click para
   * abrir PopUp com informação
   *
   */

  makeProduct = () => {
    let product = null;

    if (this.state.data !== null && this.state.data.productsList) {
      product = this.state.data.productsList[0].imgs.map((val, key) => {
        // obtem imagem do banner
        if (val.type === "slider") {
          return (
            <div className="item" key={key}>
              <div
                className="bg_projeto"
                style={{
                  backgroundImage:
                    "url(" +
                    Configs.media_prefix +
                    Configs.media.imgs +
                    val.name +
                    ")",
                }}
              >
                {" "}
              </div>
              <span className="info" onClick={this.closeSiderInfo}>
                <img
                  className="img-fluid i_icon_black"
                  src="/assets/imgs/Icon-Info-01.png"
                ></img>
              </span>
            </div>
          );
        }
      });
    }

    return product;
  };

  /**
   *
   * Página: Estufas + Produtos Estufas
   * Retorna todos os documentos com o type = generic OU techsheet
   *
   **/
  makeDcuments = () => {
    let document = null;
    if (this.state.data !== null && this.state.data.productsList) {
      document = this.state.data.productsList[0].docs.map((val, key) => {
        if (val.type === "catalog") {
          return (
            <div className="docsDownload" key={key}>
              <a
                href={insertMediaWithAppAbsPath(val.name, "docs")}
                className="download_fichaTecnica"
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/assets/imgs/Seta-03.png" alt="download" />
                {val.type === "catalog"
                  ? this.props.t("views").products.catalogo
                  : ""}
              </a>
            </div>
          );
        } else if (val.type === "techsheet") {
          return (
            <div className="docsDownload" key={key}>
              <a
                href={insertMediaWithAppAbsPath(val.name, "docs")}
                className="download_fichaTecnica"
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/assets/imgs/Seta-03.png" alt="download" />
                {val.type === "techsheet"
                  ? this.props.t("views").products.techsheet
                  : ""}
              </a>
            </div>
          );
        }
      });
    }

    return document;
  };

  /**
   *
   * Página: Estufas
   * Serve para fechar o content com a informação do produto,
   * após o click, o slideshow fica a full
   *
   **/
  closeSiderInfo = () => {
    var x = document.getElementsByClassName("product_info");

    if (x[0] !== undefined) {
      x[0].classList.toggle("off", !x[0].classList.contains("off"));
    }
    setTimeout(() => {
      window.scrollTo(0, 85);
    }, 50);

    /**/
  };

  /**
   *
   * Página: Estufas + Produtos Estufas
   * Verifica se existe imgs com aquele tipo e devolve um array com os url
   *
   **/
  getImgsArrayByType = (array, type) => {
    if (array !== undefined && array.length > 0) {
      let imgstype = extractElementsFromArrayByIsType(array, type);
      if (imgstype !== null) {
        let imgslinkarray = imgstype.map((val) => {
          return insertMediaWithAppAbsPath(val.name, "imgs");
        });
        return imgslinkarray;
      }
    }
    return null;
  };

  /**
   *
   * Página: Estufas + Produtos Estufas
   * Verifica se existe imgs com aquele tipo e devolve um array com os url
   *
   **/
  getAltForImgs = (array, type) => {
    if (array !== undefined && array.length > 0) {
      let imgstype = extractElementsFromArrayByIsType(array, type);
      if (imgstype !== null) {
        let imgslinkarray = imgstype.map((val) => {
          return val.alt;
        });
        return imgslinkarray;
      }
    }
    return null;
  };

  /*
   * vai buscar os related e controi o layout
   */
  makeRelated = (array) => {
    if (array !== null || array !== undefined) {
      let related = array.map((vale, key) => {
        let urlRelated =
          Configs.base_url +
          this.state.lang +
          "/" +
          this.props.t("menu").product_detalhe[1] +
          "/" +
          vale.product_id +
          "/" +
          vale.product_slug;

        return (
          <Fragment key={key}>
            <a
              href={urlRelated}
              style={
                vale.img === null || vale.img === undefined
                  ? { display: "none" }
                  : { display: "block" }
              }
            >
              <div
                className="related-product-img"
                style={
                  vale.img === null || vale.img === undefined
                    ? { backgroundImage: "url('')" }
                    : {
                        backgroundImage:
                          'url("' +
                          insertMediaWithAppAbsPath(vale.img[0].name, "imgs") +
                          '")',
                      }
                }
              ></div>

              <h1>{vale.product_title}</h1>
            </a>
          </Fragment>
        );
      });

      return <div className="related-box"> {related} </div>;
    }
  };

  /*
   * Onde são montadas as imagens das tabelas
   */
  getTableImgems = (array) => {
    if (array !== null && array.length > 0) {
      let img = array.map((val, key) => {
        return (
          <Fragment key={key}>
            <img src={val} alt="" />
          </Fragment>
        );
      });

      return img;
    }
    return null;
  };

  pivotalArray = (columns, rows, isMobile = false) => {
    if (isMobile) {
      let result = [];

      for (let i = 0; i < columns.length; i++) {
        let newRow = [];
        newRow.push(columns[i].sTitle);
        result.push(newRow);
      }

      for (let i = 0; i < columns.length; i++) {
        for (let j = 0; j < rows.length; j++) {
          let newCell = rows[j];
          result[i].push(newCell[i]);
        }
      }

      const tempColumns = [];
      for (const cell of columns) {
        tempColumns.push(cell.title);
      }
      return { data: result };
    } else {
      return { columns: columns, data: rows };
    }
  };

  getTable = (tabele, flag, tag) => {
    var tabelas = tabele;

    var componentes = "",
      colunas = "",
      linhas = "";

    componentes = JSON.parse(tabelas);

    componentes.columns.shift();
    componentes.columns.shift();

    colunas = componentes.columns;
    linhas = componentes.data;

    linhas.forEach((element) => {
      element.shift();
      element.shift();
    });

    var desktop = this.pivotalArray(colunas, linhas, flag);

    var col = "";
    var line = "";

    if (tag === "pc") {
      if (
        desktop.columns &&
        desktop.columns !== null &&
        desktop.columns !== undefined
      ) {
        col = desktop.columns.map((val, key) => {
          return <th key={key}> {ReactHtmlParser(val.title)}</th>;
        });
      }
      if (desktop.data && desktop.data !== null && desktop.data !== undefined) {
        line = desktop.data.map((val, key) => {
          var helpe = val.map((val2, key2) => {
            return <td key={key2}>{ReactHtmlParser(val2)}</td>;
          });

          return <tr key={key}>{helpe}</tr>;
        });
      }

      return (
        <table>
          {col !== "" ? (
            <thead>
              <tr>
                {col}
              </tr>
            </thead>
          ) : (
            <thead></thead>
          )}

          <tbody>{line}</tbody>
        </table>
      );
    } else {
      var tabelamob = desktop.data.map((val, key) => {
        var helper = val.map((val2, key2) => {
          if (key2 === 0) {
            return (
              <div key={key2} className="col-12 table-title">
                {ReactHtmlParser(val2)}
              </div>
            );
          } else {
            return (
              <span key={key2} className="col table-col">
                {ReactHtmlParser(val2)}
              </span>
            );
          }
        });

        return (
          <div className="row table-line" key={key}>
            {helper}
          </div>
        );
      });
      return tabelamob;
    }
  };

  render() {
    let product =
      this.state.data.productsList !== undefined &&
      this.state.data.productsList !== null
        ? this.state.data.productsList[0]
        : "";

    /*
     * Verifica se é um componente ou se é uma estufa normal e consoante isso utiliza um layout diferent
     */

    let tableImg = this.getImgsArrayByType(product.imgs, "generic");
    let galeryImg = this.getImgsArrayByType(product.imgs, "gallery");

    let altTablet = this.getAltForImgs(product.imgs, "generic");
    let altGallery = this.getAltForImgs(product.imgs, "gallery");

    if (product !== "") {
      if (
        (undefined === this.familySlug ||
          null === this.familySlug ||
          this.view !== this.t("menu").ghouse[1]) &&
        this.view !== this.t("menu").product_detalhe[1]
      ) {
        let phrase = this.props.t("views").automatisms.share_product;
        let link = Configs.base_url + this.props.match.url.substring(1);

        var object = JSON.parse(product.tables);

        let desktop = undefined;
        let moblie = undefined;

        if (object && object !== undefined && object !== null) {
          desktop = object.map((val, key) => {

            return (
              <div key={key} className="table-pc">
                {this.getTable(val, true, "pc")}
              </div>
            );
          });

          moblie = object.map((val, key) => {

            return (
              <div key={key} className="table-mobile">
                {this.getTable(val, true, "moblie")}
              </div>
            );
          });
        } else {
          object = null;
        }

        return (
          <>
            <div className="product">
              <div className="owl-carousel product_slideshow">
                {product !== "" ? this.makeProduct(product) : null}
              </div>
              <div className="product_info">
                {/*<div className="desc">*/}
                  <h1 className="cat">{this.props.t("menu").ghouse[0]}</h1>
                  <h1 className="title">
                    {ReactHtmlParser(product.title.replace("-", "&#8209;"))}
                  </h1>
                  <div className="desc">
                    {ReactHtmlParser(product.description)}
                  </div>
                  {product.productref !== "" && product.productref !== undefined
                    ? this.t("views").products.reference +
                      ":" +
                      product.productref
                    : ""}

                  {product !== "" ? this.makeDcuments(product.docs) : null}

                  <ShareSocialMedia nameShare={phrase} shareUrl={link} />
                  <a
                    href="#"
                    onClick={this.closeSiderInfo}
                    className="slider_close"
                    style={{
                      backgroundImage: "url(/assets/imgs/estufas-cruz.png)",
                    }}></a>
                </div>
             {/* </div>*/}
            </div>
            <div style={{ overflow: "hidden" }}>
              <div
                className="product-espcif"
                style={object === null ? { display: "none" } : null}
              >
                <div className="d-none d-xl-block">{desktop}</div>
                <div className=" d-xl-none">{moblie}</div>
                <ParallaxProvider>
                  <Parallax
                    className="custom-class"
                    x={[60, -5]}
                    tagOuter="figure"
                  >
                    <div className="specifications">
                      {this.t("views")["greenhouse"].specifications}
                    </div>
                  </Parallax>
                </ParallaxProvider>
                {/* {product !== "" ? this.getTableImgems(tableImg) : null} */}
              </div>
            </div>
            <div className="line"> </div>
            <div className="product_galeri row">
              <div className=" col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6  d-flex align-items-center">
                <img
                  alt={altGallery !== null ? altGallery[0] : "Prilux"}
                  className="galeryIMG"
                  src={galeryImg !== null ? galeryImg[0] : null}
                />
              </div>

              <div className=" col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                <div className=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex align-items-start">
                  <img
                    alt={altGallery !== null ? altGallery[1] : "Prilux"}
                    className="galeryIMG"
                    src={galeryImg !== null ? galeryImg[1] : null}
                  />
                </div>
                <div className=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex align-items-end">
                  <img
                    alt={altGallery !== null ? altGallery[2] : "Prilux"}
                    className="galeryIMG"
                    src={galeryImg !== null ? galeryImg[2] : null}
                  />
                </div>
              </div>
            </div>

            <div style={{ overflow: "hidden" }}>
              <ParallaxProvider>
                <Parallax
                  className="custom-class"
                  x={[-50, 10]}
                  tagOuter="figure"
                >
                  <div
                    style={galeryImg === null ? { display: "none" } : null}
                    className="specifications regular"
                  >
                    {this.t("views")["greenhouse"].drawings}
                  </div>
                </Parallax>
              </ParallaxProvider>
            </div>

            <div
              style={
                product.related !== undefined && product.related !== null
                  ? null
                  : { display: "none" }
              }
              className="related-content"
            >
              {product.related !== undefined && product.related !== null
                ? this.makeRelated(product.related)
                : null}

              <ParallaxProvider>
                <Parallax
                  className="custom-class"
                  x={[60, -0]}
                  tagOuter="figure"
                >
                  <div className="specifications suggestions">
                    {this.t("views")["greenhouse"].suggest}
                  </div>
                </Parallax>
              </ParallaxProvider>
            </div>
          </>
        );
      } else {
        let genericImg = extractElementsFromArrayByIsType(
          product.imgs,
          "generic"
        );
        // valida resultado obtido e gera o link absoluto da imagem
        genericImg =
          genericImg !== null
            ? insertMediaWithAppAbsPath(genericImg[0].name, "imgs")
            : null;
        let productimg = extractElementsFromArrayByIsType(
          product.imgs,
          "thumbnail"
        );
        // valida resultado obtido e gera o link absoluto da imagem
        productimg =
          productimg !== null
            ? insertMediaWithAppAbsPath(productimg[0].name, "imgs")
            : null;

        let phrase = this.props.t("views").automatisms.share_product;
        let link = Configs.base_url + this.props.match.url.substring(1);
        var object = JSON.parse(product.tables);
        let desktop = "";

      if(product.tables !== null && object !== null && object !== undefined){
        desktop = object.map((val, key) => {
        return (
          <div key={key} className="table-pc">
            {this.getTable(val, false, "pc")}
          </div>
          );
        });
      }

        return (
          <>
            <div className="estufa-content row">
              <img
                onClick={() => {
                  if(this.history != null){
                   this.props.history.goBack();
                  }
                 else {
                   let catProduct = product.category[0]!= undefined && product.category[0] ? product.category[0] : null;
                   let catProductParent = catProduct.parentID[0]!= undefined && catProduct.parentID[0] ? catProduct.parentID[0] : null;
                   let cat = this.state.data.masterCategory;

                    window.location = Configs.base_url + this.state.lang + "/" +  cat + "/" + catProductParent.id +"/"+
                        catProductParent.slug+"/"+ catProduct.id+"/"+ catProduct.slug + "/#f" + catProduct.id;
                    }

                }}
                className="closeProduct"
                src="/assets/imgs/close.png"
                alt=""
              />
              <div className="col-12	col-sm-12	col-md-6">
                <h1 className="estufa-cat">
                  {product.category[0].parentID !== "0" &&
                  Object.keys(product.category[0].parentID[0]).length > 0
                    ? product.category[0].parentID[0].title + " / "
                    : ""}

                  {product.category !== undefined &&
                  Object.keys(product.category[0]).length > 0
                    ? product.category[0].title
                    : ""}
                </h1>
                <h1 className="estufa-title ">{product.title}</h1>
                <div className="estufa-desc">
                  {ReactHtmlParser(product.description)}
                  <br></br>
                  <br></br>
                  {product.productref !== "" && product.productref !== undefined
                    ? this.t("views").products.reference +
                      ":" +
                      product.productref
                    : ""}
                </div>
                <div className="estufa-ref"></div>
                {product.docs !== undefined
                  ? this.makeDcuments(product.docs)
                  : null}

                <ShareSocialMedia nameShare={phrase} shareUrl={link} />
              </div>
              <div
                className="estufa-product-img col-12	col-sm-12	col-md-6"
                style={{ backgroundImage: "url(" + productimg + ")" }}
              ></div>
              <div className="estufa-tabel col-12	col-sm-12	col-md-12	col-lg-12">
                <div>{desktop}</div>
              </div>
            </div>
            <div
              className="product-espcif product-detail"
              style={galeryImg === null ? { display: "none" } : null}
            >
              <div className="line"></div>
              <div className="product_galeri row">
                <div className="colun-1 col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <img
                    alt={altGallery !== null ? altGallery[0] : "Prilux"}
                    className="galeryIMG"
                    src={galeryImg !== null ? galeryImg[0] : null}
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  <div className=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <img
                      alt={altGallery !== null ? altGallery[1] : "Prilux"}
                      className="galeryIMG"
                      src={galeryImg !== null ? galeryImg[1] : null}
                    />
                  </div>
                  <div className=" col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                    <img
                      alt={altGallery !== null ? altGallery[2] : "Prilux"}
                      className="galeryIMG"
                      src={galeryImg !== null ? galeryImg[2] : null}
                    />
                  </div>
                </div>
                <div className="esp-content">
                  <ParallaxProvider>
                    <Parallax
                      className="custom-class"
                      x={[60, -5]}
                      tagOuter="figure"
                    >
                      <div className="specifications">
                        {this.t("views")["greenhouse"].specifications}
                      </div>
                    </Parallax>
                  </ParallaxProvider>
                </div>
              </div>
            </div>
            <div
              style={
                product.related !== undefined && product.related !== null
                  ? null
                  : { display: "none" }
              }
              className="related-content componentes"
            >
              {product.related !== undefined && product.related !== null
                ? this.makeRelated(product.related)
                : null}

              <ParallaxProvider>
                <Parallax
                  className="custom-class"
                  x={[60, -5]}
                  tagOuter="figure"
                >
                  <div className="specifications suggestions">
                    {this.t("views")["greenhouse"].suggest}
                  </div>
                </Parallax>
              </ParallaxProvider>
            </div>
          </>
        );
      }
    } else {
      /* return "producto não existe"; */
      return <></>;
    }
  }
}

export default withTranslation()(ProductDetailView);
