import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import $ from "jquery";

import "../../scss/ProjectDetailView.scss";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import RestDataSource from "../../components/webservices/RestDataSource";

import { Configs, insertMediaWithAppAbsPath } from "../../Toolbox";

import ReactHtmlParser from "react-html-parser";

import ShareSocialMedia from "../ShareSocialMedia";

import { Parallax, ParallaxProvider } from "react-scroll-parallax";
import VisibilitySensor from "react-visibility-sensor";
/**
 * For New Detail
 */

class ProjectDetailView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: null,
      data: null,
      articleCategory: null,
      articleTitle: null,
      articleSubTitle: null,
      articleDescription: null,
      articleWord: null,
      articleImageCarousel: null,
    };

    this.t = props.t; //tradutor
    this.i18n = null;

    this.logicLatch = false; // TRINCO LÓGICO - FALSE aberto | TRUE fechado

    this.optionId = undefined; // ID da CATEGORIA dos artigos
    this.optionSlug = undefined; // respectivo slug

    if (this.props.match && this.props.match.params) {
      this.optionId = this.props.match.params.optionId;
      this.optionSlug = this.props.match.params.optionSlug;
    }

    this.restURL = null; // = `${this.lang}/rest/news/content/${this.optionId}`;

    this.webserver = new RestDataSource(); // para comunicação com a API (REST)
    this.restURI = `rest/article`; // para utilização nos pedidos de dados à API / URI do pedido
  }

  /**
   * @description
   * Para inicializaçãpo dos componentes e afins após o seu carregamento no DOM
   *
   */
  componentDidMount() {
    window.isFristTimeLoad = true;
    window.sessionStorage.removeItem("stickyBFM"); //limpeza das variáveis em SessionStorage
    this.t = this.props.t; //para utilização nas ações de tradução
    this.i18n = this.props.i18n;

    let {
      lang,
      data,
      articleCategory,
      articleTitle,
      articleSubTitle,
      articleDescription,
      articleWord,
      articleImageCarousel,
    } = this.state;

    lang = this.i18n.language;
    data = this.props.pData ? this.props.pData : null;
    articleCategory = this.props.articleCategory
      ? this.props.articleCategory
      : null;
    articleTitle = this.props.articleTitle ? this.props.articleTitle : null;

    articleSubTitle = this.props.articleSubTitle
      ? this.props.articleSubTitle
      : null;

    articleDescription = this.props.articleDescription
      ? this.props.articleDescription
      : null;
    articleWord = this.props.articleWord ? this.props.articleWord : null;
    articleImageCarousel = this.props.articleImageCarousel
      ? this.props.articleImageCarousel
      : null;

    this.setState({
      lang,
      data,
      articleCategory,
      articleTitle,
      articleSubTitle,
      articleDescription,
      articleWord,
      articleImageCarousel,
    });

    /* const requestURI = `${this.i18n.language}/${this.restURI}/fid${this.optionId}`;
     */
    const requestURI = `${this.i18n.language}/${this.restURI}/fid${this.optionId}`;

    // para utilização de JQUERY (OWL)
    window.jQuery = $;
    require("owl.carousel");

    this.logicLatch = true;
    this.getDataFromAPI(requestURI);
  }

  componentDidUpdate(prevProps, prevState) {
    let { lang } = prevState;

    if (!window.isFristTimeLoad) {
      return;
    }

    const requestURI = `${this.i18n.language}/${this.restURI}/fid${this.optionId}`;

    // novo idioma seleccionado, actualiza-o
    if (lang !== this.i18n.language) {
      lang = this.i18n.language;

      this.setState({ lang }, () => {
        // idioma alterado, obtem os dados da API no novo idioma
        if (this.logicLatch === false) {
          // fecha o trinco para efeitos de acesso ao pedido de
          // novos conteúdos à API
          this.logicLatch = true;
          // executa pedido
          this.getDataFromAPI(requestURI);
        }
      });
    }

    let oldUrl = window.sessionStorage.getItem("oldUrl")
        ? window.sessionStorage.getItem("oldUrl")
        : prevProps.match.url,
      newUrl = window.location.href,
      isSameUrl = true;

    if (!newUrl.includes(oldUrl) || window.isFristTimeLoad) {
      isSameUrl = false;
    }
    var onwNavBar = this.props.navData;
    if (onwNavBar === undefined) {
      onwNavBar = JSON.parse(window.sessionStorage.getItem("navigation"));
    }

    /**StickyMenu **/
    if (
      onwNavBar &&
      "projects" in onwNavBar &&
      (this.state.stickyBFM === null || !isSameUrl)
    ) {
      let { stickyBFM } = this.state;

      if ("projects" in onwNavBar) {
        stickyBFM = onwNavBar.projects;
        this.setState({ stickyBFM }, () => {
          let navElems = stickyBFM.map((val) => {
            return {
              id: val.id,
              text: val.title,
              uri: `${this.t("menu").projects[1]}/?filter=${val.slug}&fc1=${
                val.id
              }`,
              onClick: (e) => {
                this.handleStickyMenuItemClick(e);
              },
            };
          });

          if (Array.isArray(navElems) /*&& !filtered*/) {
            navElems.unshift({
              id: -1,
              text: this.t("all"),
              uri: `${this.t("menu").projects[1]}`,
              onClick: (e) => {
                this.dealWithFilterClick(e);
              },
            });
          }
          window.isFristTimeLoad = false;
          this.props.setSFMOptions(navElems);
          window.sessionStorage.setItem("stickyBFM", JSON.stringify(navElems));
        });
      }
    }
  }

  getDataFromAPI = (requestURL) => {
    this.webserver.getData(requestURL, (result, xhr) => {
      this.logicLatch = false;

      if (
        typeof result === "object" &&
        result.article !== undefined &&
        Object.keys(result.article).length > 0
      ) {
        let {
          data,
          articleCategory,
          articleTitle,
          articleSubTitle,
          articleDescription,
        } = this.state;

        articleCategory =
          result.article.category !== undefined &&
          result.article.category !== null
            ? result.article.category[0].title
            : "";

        articleTitle =
          result.article.title !== undefined && result.article.title !== null
            ? result.article.title
            : "";

        articleSubTitle =
          result.article.subTitle !== undefined &&
          result.article.subTitle !== null
            ? result.article.subTitle
            : "";

        articleDescription =
          result.article.description !== undefined &&
          result.article.description !== null
            ? result.article.description
            : "";

        data = result;

        this.setState(
          {
            data,
            articleCategory,
            articleTitle,
            articleSubTitle,
            articleDescription,
          },
          () => {
            // cria o corpo da página
            this.setupBodyContent();
          }
        );
      }
    });
  };

  /**
   * @description
   * Para inicialização de elementos OWL do corpo da página
   */
  setupBodyContent = () => {
    if (
      this.state.data.article !== null &&
      this.state.data.article !== undefined
    ) {
      window.$owl = $(".carousel_project").owlCarousel({
        nav: false,
        dots: true,
        autoplay: false,
        loop: true,
        margin: 0,
        responsiveClass: true,
        responsive: {
          0: {
            items: 1,
          },
          992: {
            items: 1,
          },
          1200: {
            items: 1,
          },
        },
        onTranslate: function () {
          $(".owl-item")
            .find("video")
            .each(function () {
              this.muted = true;
            });
        },
        onResized: function () {
          $(".owl-item")
            .find("video")
            .each(function () {
              this.muted = true;
            });
        },
      });
      $(".owl-item")
        .find("video")
        .each(function () {
          this.muted = true;
        });

      /*  //seta do lado esquerdo
      var setaPrev = document.getElementsByClassName("owl-prev");
      var IconPrev = setaPrev[0].getElementsByTagName("span");
      IconPrev[0].innerHTML =
        ' <img src="../../../../assets/imgs/Seta-01.png" alt=""/>';

      //seta do lado direito
      var setaNext = document.getElementsByClassName("owl-next");
      var IconNext = setaNext[0].getElementsByTagName("span");
      IconNext[0].innerHTML =
        ' <img src="../../../../assets/imgs/Seta-02.png" alt=""/>'; */
    }
  };

  makeDetail = () => {
    const {
      data,
      articleCategory,
      articleTitle,
      articleSubTitle,
      articleDescription,
    } = this.state;

    let article = null;

    if (this.state.data !== null && this.state.data.article) {
      article = this.state.data.article;
      var newId = article.category;

      var OldStickyFM = document.querySelectorAll(".sticky-fm ul");

      $(OldStickyFM).find("li").removeClass("active");
      $(OldStickyFM)
        .find("li#" + newId + "sticky")
        .addClass("active");

      return (
        <div className="conteudo_detail_project">
          <h6 className="categoryName">{this.t("menu").projects[0]}</h6>
          <h1 className="titleProject">{`${articleTitle} `}</h1>
          <h1 className="titleProject_1">{`${articleSubTitle} `}</h1>
          <div className="descriptionProject">
            {ReactHtmlParser(`${articleDescription} `)}
          </div>
        </div>
      );
    }
  };

  makeCarousel = () => {
    const { data, articleImageCarousel } = this.state;

    let article = null;
    let carousel = null;

    if (this.state.data !== null && this.state.data.article) {
      carousel = this.state.data.article.imgs.map((val, key) => {
        let img = insertMediaWithAppAbsPath(val.name, "imgs");

        if (val.type === "video") {
          img = img.toString();
          return (
            <div className="item" key={val.id}>
              <video controls autoPlay loop muted playsInline className="img_item">
                <source src={img} type="video/mp4" />
              </video>
            </div>
          );
        } else {
          return (
            <div className="item" key={val.id}>
              <img
                className="img_item"
                src={img}
                alt={val.alt !== "" ? val.alt : val.title}
              />
            </div>
          );
        }
      });
      return carousel;
    }
  };

  /**
   * Componente de partiha de artigo nas redes sociais
   */
  makeSocialMedia = () => {
    let link = Configs.base_url + this.props.match.url.substring(1);
    let phrase = this.props.t("views").projects.share_project;

    return <ShareSocialMedia nameShare={phrase} shareUrl={link} />;
  };

  /**
   * @description
   * Tratamento do click em items do sticky menu, se este estiver localizado
   * na própria VIEW faz scroll da mesma para o topo, se estiver localizado em
   * VIEW diferente procede à ancora para a respectiva VIEW
   *
   * @param {*} e eventHandler
   */
  handleStickyMenuItemClick = (e) => {
    const target = e.target;

    // obtem valores de params a partir das props.match
    // se estes valores estiverem presentes no link que foi clicado
    // encaminhar o conteúdo da VIEW para o topo da mesma
    if (target.href.indexOf(this.optionId) >= 0) {
      e.preventDefault();
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  onVisibilityChange = (isVisible = false) => {
    if (isVisible) {
      window.dispatchEvent(new Event("resize"));
    }
  };
  render() {
    return (
      <div className="body_project_detail">
        <img
          onClick={() => this.props.history.goBack()}
          className="closeArticle"
          src="../../../../assets/imgs/close.png"
          alt=""
        />
        <div className="bg_gray_1"></div>
        {this.state.data !== undefined ? this.makeDetail() : null}
        <div className="animate-content">
          <ParallaxProvider>
            <Parallax className="custom-class" x={[60, 0]} tagOuter="figure">
              <div className="word_project">
                {this.props.t("menu").projects[0]}
              </div>
            </Parallax>
          </ParallaxProvider>
        </div>

        <div className="line"></div>

        <div className="owl-carousel carousel_project">
          {this.state.data !== undefined ? this.makeCarousel() : null}
        </div>

        <div className="ShareSocialMediaContent">
          {this.state.data !== undefined ? this.makeSocialMedia() : null}
        </div>
      </div>
    );
  }
}

export default withTranslation()(ProjectDetailView);
setTimeout(() => {
  window.dispatchEvent(new Event("resize"));
}, 1000);
