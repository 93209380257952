import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";

import RestDataSource from "../../components/webservices/RestDataSource";
import $ from "jquery";
import {
  extractElementsFromArrayByIsType,
  insertMediaWithAppAbsPath,
  Configs,
} from "../../Toolbox";
//import Forms from "../../../components/Forms";
//import AlertMessage from "../../AlertMessage";
import Forms from "../../components/Forms";
import AlertMessage from "../AlertMessage";

import "../../scss/views/StoreHomeView.scss";
import { First } from "react-bootstrap/esm/PageItem";
import { RedditIcon } from "react-share";

class StoreHomeView extends Component {
  constructor(props) {
    super(props);

    /**
     * @description
     *  campos do formulário de submissão de pedido de informação
     * Nome, Email, Telemóvel, Morada
     */
    this.fields = [
      Object.assign({ required: "required" }, Configs.form_fields.subject), //assunto
      Object.assign({ required: "required" }, Configs.form_fields.name), // mandatório
      Object.assign({ required: "required" }, Configs.form_fields.email), // mandatório
      Object.assign({ required: "required" }, Configs.form_fields.mobile), // mandatório
      Object.assign({ required: "required" }, Configs.form_fields.address), //mandatório
      Object.assign({ required: "required" }, Configs.form_fields.rgpdconsent), //mandatório
    ];

    this.state = {
      lang: null,
      data: {},
      menu: {}, //listagem de categorias e subcategorias
      frmFields: this.fields, // campos do formulário
      submitRes: null, // para registo de mensagem de retorno da FORM
      productList: null, // lista de produtos para armazenamento de info de API
      wishList: [], // lista de produtos guardados para envio de email de intenção de compra
    };

    // p utilização nas acções de tradução
    this.t = this.props.t; //tradutor
    this.i18n = null;
    this.selectedID = null;
    this.logicLatch = false; //TRINCO LÓGICO - FALSE aberto | TRUE fechado

    this.webserver = new RestDataSource(); // para comunicação com a API (REST)
    this.restURI = `rest/storeProducts/content`; //familyID

    // var restURIMenu = `rest/storeMenu/content/`; // para utilização nos pedidos de dados à API / URI do pedido --> Construção do Menu
    this.thirdPartyRest = new RestDataSource();

    this.restSendContactURI = `/rest/wishlist/send`; //para utilização nos pedidos de dados à API / URI do pedido

    // define o subject da mensagem
    this.fields[0].value = this.props.t("menu").store[0];

    //this.dataContainer = document.getElementsByClassName("card_product");

    var idCategory = this.setCategory; //id para obter dados dos produtos consoante a categoria
  }

  /**
   * @description
   * Para inicialização dos componentes e afins após o seu
   * carregamento no DOM
   */
  componentDidMount() {
    window.sessionStorage.removeItem("stickyBFM");
    this.t = this.props.t; // p utilização nas acções de tradução
    this.i18n = this.props.i18n;

    let { lang, form, menuProducts } = this.state;
    lang = this.i18n.language;

    this.setState({ lang });

    this.logicLatch = true;

    //this.getData();

    // executa pedido à API para fabricar os produtos...
    this.getDataFromAPI(this.makeURI(), true); //(requestURI);
  }

  /**
   * @description
   * Verifica se existem alterações nas props e/ou state e executa
   * as necessárias alterações em conformidade, exemplo, quando se
   * altera o idioma do site poderá ser necessário obter novos termos
   * no novo idioma para preenchimento do layout...
   * caso se verifique que os mesmos já existam dever-se-à evitar a
   * utilização deste recurso, isto porque, poderá estar a criar um
   * conjunto de pedidos desnecessários e que eventualmente tornarão
   * esta solução mais lenta
   *
   * @param {object} prevProps
   * @param {object} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    let { lang } = prevState;

    if (lang !== this.i18n.language) {
      lang = this.i18n.language;

      this.setState({ lang }, () => {
        //idioma alterado, obtem os dados da API no novo idioma
        if (this.logicLatch === false) {
          //fecha o trinco para efeitos de acesso ao pedido de
          //novos conteúdos à API
          this.logicLatch = true;

          this.getData();

          //executa o pedido
          this.getDataFromAPI(this.makeURI()); //(requestURI);
        }
      });
    }

    /**Mudança de seção durante o scroll */
    $.fn.isInViewport = function () {
      var elementTop = $(this).offset().top;
      var elementBottom = elementTop + $(this).outerHeight();

      var viewportTop = $(window).scrollTop();
      var viewportBottom = viewportTop + $(window).height();

      return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    var isCollidedToSubMenu = false;
    var isCollidedToSubMenuOffSet = 100000;

    var isCollidedToSticky = false;
    var isCollidedOffSetToSticky = 100000;

    $(window).on("resize scroll", function () {
      let $menuLateral = $(".body_menuProducts"); //Desktop
      let $wishListAndForm = $(".lista"); //Desktop
      let $iconList = $(".icon_info"); //Mobile
      let $iconMenuLateral = $(".menuProductsMob"); //Mobile
      let $appFooter = $(".app-footer");

      //Deteção de Colisão no MenuLateral das Categorias
      if ($menuLateral.isColliding($(".app-footer"))) {
        if (!isCollidedToSubMenu) {
          isCollidedToSubMenu = true;
          isCollidedToSubMenuOffSet = $(window).scrollTop();
        }
        let fixedSize =
          $appFooter.offset().top - $menuLateral.outerHeight() - 110 + "px";

        $menuLateral.css({
          position: "absolute",
          top: fixedSize,
        });
      }

      //Wish List and Interest Form
      if ($wishListAndForm.isColliding($(".app-footer"))) {
        if (!isCollidedToSticky) {
          isCollidedToSticky = true;
          isCollidedOffSetToSticky = $(window).scrollTop();
        }
        let fixedSize =
          $appFooter.offset().top - $wishListAndForm.outerHeight() - 110 + "px";
        $wishListAndForm.css({
          position: "absolute",
          top: fixedSize,
        });
      }

      //Icon Wish List in Mobile
      if ($iconList.isColliding($(".app-footer"))) {
        if (!isCollidedToSticky) {
          /* isCollidedToSticky = true;
          isCollidedOffSetToSticky = $(window).scrollTop(); */

          setTimeout(() => {
            isCollidedToSticky = true;
            isCollidedOffSetToSticky = $(window).scrollTop();
          }, 10);
        }
        let fixedSize =
          $appFooter.offset().top - $iconList.outerHeight() - 110 + "px";
        $iconList.css({
          position: "absolute",
          top: fixedSize,
        });
      }

      //Icon Side Menu in Mobile
      if ($iconMenuLateral.isColliding($(".app-footer"))) {
        if (!isCollidedToSticky) {
          isCollidedToSticky = true;
          isCollidedOffSetToSticky = $(window).scrollTop();
        }
        let fixedSize =
          $appFooter.offset().top - $iconMenuLateral.outerHeight() - 110 + "px";
        $iconMenuLateral.css({
          position: "absolute",
          top: fixedSize,
        });
      }

      if (isCollidedOffSetToSticky >= $(window).scrollTop()) {
        $menuLateral.css({
          position: "fixed",
          top: "150px",
        });

        $wishListAndForm.css({
          position: "fixed",
          top: "150px",
        });

        $iconList.css({
          position: "fixed",
          top: "150px",
        });

        $iconMenuLateral.css({
          position: "fixed",
          top: "150px",
        });
      }
    });
  }

  /**
   * @description
   *  Método responsável para fazer o pedido de dados à API
   *
   */
  makeURI() {
    let requestURI = `${this.i18n.language}/${this.restURI}`;
    requestURI += `/${this.selectedID}`;

    return requestURI;
  }

  /**
   * @description
   * Método responsável por usar o pedido de dados à API... a usar nas life cycle
   * componentDidMount e/ou componentDidUpdate
   * Constroi o corpo da loja c/ produtos consoante o id da subCategoria selecionado
   */
  getData = () => {
    // debugger;
    this.webserver.getData(this.makeURI(), (result, xhr) => {
      this.logicLatch = false;

      if (xhr.status === 200 && "data" in xhr && typeof xhr.data === "object") {
        let { data } = this.state;
        data = result;

        this.setState({ data });
      }
    });
  };

  /**
   * @description
   * função para o pedido de dados à API que constroem o menu do lado esquerdo
   * ... a usar nas life cycle componentDidMount e/ou componentDidUpdate
   * @param {*} requestURL
   * @param {*} isFirstCall
   */
  getDataFromAPI = (requestURL, isFirstCall) => {
    this.restURIMenu = `${this.i18n.language}/rest/storeMenu/content`;

    // obtem lista de categorias para definição de MENU LATERAL
    this.thirdPartyRest.getData(this.restURIMenu, (result, xhr) => {
      this.logicLatch = false;
      // verifica se o pedido obteve resultados...
      if (
        xhr &&
        "status" in xhr &&
        xhr.status === 200 && // pedido OK
        "data" in xhr && // data presente
        xhr.data.length > 0 // data possui conteúdo
      ) {
        let { menu, menuProducts } = this.state;
        menu = result;

        if (menu !== null && menu.length > 0) {
          menuProducts = menu[0].id;
          this.selectedID = menuProducts;
        }
        if (isFirstCall) {
          this.getData();
        }

        this.setState({ menu, menuProducts });
      }
    });
  };

  /**
   * @description
   * Icon do lado direito que fecha a listagem de produtos desejados
   * @param {*} e
   */
  closeSiderInfo = (e) => {
    e.preventDefault();
    e.stopPropagation();
    var list = document.getElementsByClassName("lista");
    list[0].style.right = "-380px";

    var icon = document.getElementsByClassName("icon_info");
    icon[0].style.display = "block";
  };

  /**
   * @description
   * Icon do lado direito que abre a listagem de produtos desejados
   * @param {*} e
   */
  openSiderInfo = (e) => {
    if (e && e._dispatchListeners) {
      e.preventDefault();
      e.stopPropagation();
    }

    var info = document.getElementsByClassName("lista");
    info[0].style.right = "0px";
    var icon = document.getElementsByClassName("icon_info");
    icon[0].style.display = "none";
  };

  /**
   * @description
   * função para gerir os dados para construir o menu
   * localizado no lado esquerdo
   * É constituido por categorias de produtos descontinuados
   * Menu constituido pela categoria raiz
   * e categorias descentes da mesma
   *
   * --> parentCategory
   * --> childCategory
   */
  makeListProduct = () => {
    let result = null;

    $(".parentCategory").each(function (index) {
      $(this).on("click", function () {
        if ($("a[aria-expanded='true']").length > 0) {
          $("a[aria-expanded='true']").attr("aria-expanded", "false");
          $(".collapse.show").removeClass("show");
          $(".childCategory a").removeClass("show");
        }
      });
    });

    if (
      this.state.menu !== null &&
      this.state.menu !== undefined &&
      Array.isArray(this.state.menu)
    ) {
      result = this.state.menu.map((val, key) => {
        if ($("a[aria-expanded='true']").length === 0) {
          $("a[aria-expanded='false']").first().attr("aria-expanded", "true");
          $(".collapse").first().addClass("show");
          $(".childCategory a").first().addClass("show");
        }

        return (
          <Fragment key={val.id}>
            <a
              className="parentCategory"
              data-toggle="collapse"
              href={`#${val.slug}`}
              //  role="button"
              aria-expanded="false"
              aria-controls={`${val.id}`}
            >
              {val.title}
              <span>
                <i className="fas fa-chevron-right"></i>
              </span>
            </a>

            <div className="collapse" id={`${val.slug}`}>
              <ul>
                {val.subcat.map((valSub, key) => {
                  return (
                    <li className="childCategory" key={valSub.id}>
                      <a
                        // href={`#f${valSub.id}`}
                        href={`/${this.i18n.language}/${
                          this.t("menu").store[1]
                        }/${val.id}/${val.slug}/${valSub.id}/${valSub.slug}/#f${
                          valSub.id
                        }`}
                        //role="button"
                        // data-idcategoria={val.id}
                        data-idcategoria={val.id}
                        onClick={this.handleMenuClick}
                      >
                        {valSub.title}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Fragment>
        );
      });
    }
    return result;
  };

  /**
   * @description
   * Abrir o menu de categoria de produtos
   * apenas em dispositivos móveis
   * --> situado no lado esquerdo
   */
  openMenuProduct = (e) => {
    e.preventDefault();
    e.stopPropagation();

    var menuProduct = document.querySelectorAll("div .body_menuProducts");
    menuProduct[0].style.left = "0px";

    var iconMenuProduct = document.querySelectorAll("div .menuProductsMob");
    iconMenuProduct[0].style.left = "-40px";
  };

  /**
   * @description
   * Fechar o menu de categoria de produtos
   * apenas em dispositivos móveis
   * --> situado no lado esquerdo
   */
  closeMenuProduct = (e) => {
    e.preventDefault();
    e.stopPropagation();

    var menuProduct = document.querySelectorAll("div .body_menuProducts");
    menuProduct[0].style.left = "-600px";

    var iconMenuProduct = document.querySelectorAll("div .menuProductsMob");
    iconMenuProduct[0].style.left = "15px";
  };

  /**
   * @description
   * Default event handler...
   *
   * @param {*} e
   */
  handleMenuClick = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const target = e.target;
    const idcategoria = $(target).data("idcategoria");
    //debugger;
    $(".childCategory").find("a").removeClass("show");
    $(target).addClass("show");

    this.selectedID = idcategoria;
    const currentID = parseInt(this.state.data.content[0].parentID);

    if (idcategoria !== currentID) {
      this.getData();
    }

    var element = target.href
      .substring(target.href.lastIndexOf("/") + 1)
      .replace("#", "");

    setTimeout(() => {
      var ele = document.getElementById(element);
      if (ele !== null) {
        window.scrollTo({
          top: ele.offsetTop,
          behavior: "smooth",
        });
      }
    }, 10);

    /**
     * verifica se não está em computador e executa a
     * função para fechar o menu após o click da categoria pretendida
     */
    if (window.mobileAndTabletCheck() !== "Desktop") {
      this.closeMenuProduct(e);
    }
  };

  /**
   * @description
   * Lista de Produtos Descontinuados consoante o click
   * makeListProduct
   **/
  makeProducts = () => {
    var tplString = ""; // template string a ser usada no array map
    let category;

    if (
      this.state.data !== null &&
      this.state.data !== undefined &&
      this.state.data.content
    ) {
      category = this.state.data.content.map((val, key) => {
        var tplString = val.productlist !== undefined ? val.productlist : null;

        let id = 0;

        return (
          <div className="cardsBody" id={"f" + val.id} key={val.id}>
            <h1 className="titleCategory">{val.title}</h1>
            {tplString
              ? (tplString = tplString.map((product, index) => {
                  ++id;

                  // obtem imagem do icon
                  let img = extractElementsFromArrayByIsType(
                    product.imgs,
                    "icon"
                  );

                  // valida resultado obtido e gera o link absoluto da imagem
                  img =
                    img !== null
                      ? insertMediaWithAppAbsPath(img[0].name, "imgs")
                      : null;

                  let alt =
                    product.imgs[0] !== null && product.imgs[0] !== undefined
                      ? product.imgs[0].alt
                      : null;

                  return (
                    /*   <div
                      className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2" 
                      key={product.id}
                    > */
                    <div
                      className="card"
                      style={{ width: "200px" }}
                      id={id}
                      key={product.id}
                    >
                      <img
                        className="card-img-top"
                        src={img !== null ? img : "/assets/imgs/noImage.png"}
                        alt={alt}
                      />

                      <div className="card-body">
                        <h1 className="card-title">{product.title}</h1>
                        <h6 className="card-subTitle">
                          Ref. {product.productref}
                        </h6>
                        <p className="card-text">{product.price} €</p>
                        <button
                          className="btn btn-primary addProduct"
                          onClick={(e) => {
                            this.clickWishThisEventHandler(product);
                            setTimeout(() => {
                              this.openSiderInfo(e);
                            }, 0);
                          }}
                        >
                          {this.props.t("views").store.btnAddWish}
                        </button>
                      </div>
                    </div>
                    /*     </div>*/
                  );
                }))
              : null}
          </div>
        );

        return tplString;
        /*  window.sessionStorage.setItem("product", JSON.stringify(tplString)); */
      });

      return category;
    }
  };

  /**
   * Tratamento do evento click do botão... Pedir Info...
   * @param {*} e
   */
  clickWishThisEventHandler(itemToAdd) {
    let productListSTR = window.sessionStorage.getItem("product");

    if (productListSTR === "" || productListSTR === null) {
      productListSTR = "[]";
    }

    let productListArr = JSON.parse(productListSTR);
    let isNewItemToAdd = true;
    for (const key in productListArr) {
      if (productListArr.hasOwnProperty(key)) {
        const element = productListArr[key];
        if (itemToAdd.id === element.id) {
          isNewItemToAdd = false;
          break;
        }
      }
    }

    if (isNewItemToAdd) {
      productListArr.push(itemToAdd);
    }

    window.sessionStorage.setItem("product", JSON.stringify(productListArr));

    this.setState(itemToAdd);
    return;
  }

  /**
   * @description
   * Formulário de pedido de informação sobre os produtos
   * descontinuados
   *
   * Fase 1: Icon "I"
   * Fase 2: Lista de produtos selecionados(adicionar dados em SessionStore)
   * Fase 3: Formulário do pedido para enviar email(makeForm())
   */
  makeWishList = () => {
    let WishListSTR = window.sessionStorage.getItem("product");

    if (WishListSTR === "" || WishListSTR === null) {
      WishListSTR = "[]";
    }

    let wishListArr = JSON.parse(WishListSTR);

    if (wishListArr.length !== 0 && wishListArr) {
      var count = 0;
      count = wishListArr.length;
    }

    return (
      <div className="col-lg-2">
        <div className="icon_info" onClick={this.openSiderInfo}>
          <img
            className="i_icon_white infoIn"
            src="/assets/imgs/Icon-Info-02.png"
            alt="icon_info"
          />

          <span className="count">{count}</span>
        </div>
        <div className="lista">
          <div className="headList" onClick={this.closeSiderInfo}>
            <span className="backToList" onClick={this.hideForm}>
              <i className="fas fa-chevron-left"></i>
            </span>

            <h1 className="titleLista">
              {this.props.t("views").store.nameList}
            </h1>
          </div>

          <ul className="list-group">
            {wishListArr.map((val, key) => {
              // obtem imagem do icon
              let img = extractElementsFromArrayByIsType(val.imgs, "icon");

              // valida resultado obtido e gera o link absoluto da imagem
              img =
                img !== null
                  ? insertMediaWithAppAbsPath(img[0].name, "imgs")
                  : null;

              //alt de cada imagem
              let alt =
                val.imgs[0] !== null && val.imgs[0] !== undefined
                  ? val.imgs[0].alt
                  : null;

              return (
                <li className="eachProduct" key={val.id}>
                  <img
                    src={img !== null ? img : "/assets/imgs/noImage.png"}
                    alt={alt}
                    className="imgProductList"
                  />
                  <div className="finalList col-8">
                    <div className="nameProd">
                      <h1 className="title">{val.title}</h1>
                      <h6 className="text">Ref. {val.productref}</h6>
                      <h6 className="text">{val.price}€</h6>
                    </div>

                    <div className="tools">
                      <span
                        className="trash"
                        onClick={(e) => this.cleanProduct(val, e)}
                      >
                        <i className="fas fa-trash-alt"></i>
                      </span>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>

          <div className={wishListArr.length === 0 ? "noResults" : "d-none "}>
            <p>{this.props.t("views").products.noProducts}</p>
          </div>

          <Fragment>
            <Forms
              id="requestProduct"
              fields={this.state.frmFields}
              onSubmit={this.handleSubmit}
            />
            <AlertMessage
              onClose={this.handleCloseAlert}
              resStats={this.state.submitRes}
              myClass={`submit-status alert alert-dismissible fade ${
                this.state.submitRes !== null && this.state.submitRes.msg
                  ? "show"
                  : ""
              }`}
            >
              <span>
                {this.state.submitRes !== null && this.state.submitRes.msg
                  ? this.state.submitRes.msg
                  : ""}
              </span>
            </AlertMessage>
          </Fragment>

          <button
            className={wishListArr.length === 0 ? "d-none" : "btn nextStep"}
            onClick={this.showForm}
          >
            {this.props.t("views").store.btnNext}
          </button>
        </div>
      </div>
    );
  };

  /**
   * @description
   * click to delete a product
   *
   * @param {*} itemToRemove
   * @param {*} e
   */
  cleanProduct = (itemToRemove, e) => {
    e.preventDefault();
    var eachProduct = window.sessionStorage.getItem("product");

    eachProduct = JSON.parse(eachProduct);

    eachProduct.forEach((item, index) => {
      if (item.id === itemToRemove.id) {
        eachProduct.pop(index);
        return;
      }
    });

    window.sessionStorage.setItem("product", JSON.stringify(eachProduct));
    this.setState({ wishListArr: eachProduct });
  };

  /**
   * @description
   * Show form with click on Next Button
   *
   * @param {*} e
   */
  showForm = (e) => {
    e.preventDefault();

    var showForm = document.getElementById("requestProduct");
    var hideList = document.getElementsByClassName("list-group");
    var btnNext = document.getElementsByClassName("nextStep");
    var backList = document.getElementsByClassName("backToList");

    if (
      showForm &&
      showForm !== undefined &&
      hideList &&
      hideList !== undefined &&
      btnNext &&
      btnNext !== undefined
    ) {
      hideList[0].style.display = "none";
      btnNext[0].style.display = "none";
      showForm.style.display = "block";
      backList[0].style.display = "block";
    }
  };

  /**
   * @description
   * Hide form with click on Next Button
   *
   * @param {*} e
   */
  hideForm = (e) => {
    e.stopPropagation();
    e.preventDefault();

    var hideForm = document.getElementById("requestProduct");
    var showList = document.getElementsByClassName("list-group");
    var btnNext = document.getElementsByClassName("nextStep");
    var backList = document.getElementsByClassName("backToList");
    var title = document.getElementsByClassName("titleLista");

    if (
      hideForm &&
      hideForm !== undefined &&
      showList &&
      showList !== undefined &&
      btnNext &&
      btnNext !== undefined
    ) {
      showList[0].style.display = "block";
      btnNext[0].style.display = "block";
      hideForm.style.display = "none";
      backList[0].style.display = "none";
      title[0].style.paddingBottom = "0px";
    }
  };

  /**
   * @description
   * Procede ao tratamento do evento de submissão da FORM
   *
   * @param {*} e
   *
   */
  handleSubmit = (e) => {
    e.preventDefault();

    const target = e.target;
    const { lang } = this.state;

    let myForm = document.getElementById("requestProduct");
    let formLoader = document.getElementById("formLoader");

    if (formLoader !== undefined) {
      formLoader.classList.add("l-visible");
    }

    if (myForm !== undefined) {
      let data2Send = new FormData(myForm);
      data2Send.append("products", window.sessionStorage.getItem("product"));

      if (data2Send instanceof FormData && this.webserver) {
        this.webserver.sendRequest(
          "POST",
          `${lang}/${this.restSendContactURI}`,
          (result, xhr) => {
            if (formLoader !== undefined) {
              formLoader.classList.remove("l-visible");
            }

            this.dealWithAPIReturnedData(result, xhr);
          },
          data2Send
        );
      }
    }
  };

  dealWithAPIReturnedData = (result, xhr) => {
    if (
      xhr.status === 200 &&
      "res" in result &&
      result.res !== undefined &&
      result.res !== null
    ) {
      let { submitRes } = this.state;
      submitRes = result;

      // procede ao reset da FORM se resposta for positiva...
      if (submitRes.res) {
        document.getElementById("requestProduct").reset();
      }
      //clear sessionStorage
      this.setState({ submitRes });
    }
  };

  render() {
    return (
      <div className="storeProducts">
        {/* <h1 className="titleStore">{this.props.t("menu").store[0]}</h1> */}
        <div className="row bodyStore">
          <div className="col-lg-2">
            {/*  <div className="menu-products"> */}
            <div
              className="menuProductsMob d-lg-none"
              onClick={this.openMenuProduct}
            >
              <span className="m_1"></span>
              <span className="m_2"></span>
              <span className="m_3"></span>
            </div>
            <div className="body_menuProducts">
              <div
                className="closeMenu d-lg-none"
                style={{ backgroundImage: "url(/assets/imgs/close.png)" }}
                onClick={this.closeMenuProduct}
              ></div>
              {this.makeListProduct()}
            </div>
            {/* </div> */}
          </div>
          <div className="col-lg-8">
            <h1 className="titleStore">{this.props.t("menu").store[0]}</h1>
            {this.makeProducts()}
          </div>
          {this.makeWishList()}
        </div>
      </div>
    );
  }
}
export default withTranslation()(StoreHomeView);
