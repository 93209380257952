import React, { Suspense } from "react";

/**
 * v0 @ 2020/06 - I18N
 *
 * @description
 * Framework clientside REACT iniciada em 06/2020.
 *
 * @packages
 * react-route-dom => This package provides URL routing, which allows the content
 *                    presented to the user tobe selected based on the browser’s
 *                    current URL
 *                  > npm install react-router-dom
 *
 * axios           => This package is used to make HTTP requests and will
 *                    be used to access RESTful
 *                  > npm install axios
 *
 * fontawsome      => ...
 *                  > npm install @fortawesome/fontawesome-free@5.6.1
 *
 * react-i18next   => is a internationalization framework for React / React Native
 *                    https://react.i18next.com/
 *                    https://medium.com/@ricklee_10931/react-multi-lingual-with-react-i18next-57879f986168
 *                  > npm i i18next i18next-xhr-backend react-i18next -S
 *
 * react-file-reader
 *
 * jQuery          => ...
 *
 * @preprecessors
 * SASS            => ...
 *                  > npm install node-sass --save
 *
 * @dependencies
 * Bootstrap       => "instalado" no ficheiro index.html, é possível
 *                    instalar a versão Bootstrap p React
 *
 *
 * CountUp         => ... Efeito de contagem aplicado Somos Globais
 *                    npm instal react-countup
 *                    https://www.npmjs.com/package/react-countup
 *
 * React Scroll       => ...Efeito aplicado em palavras soltas
 * Parallax           npm i react-scroll-parallax --save
 *                    https://www.npmjs.com/package/react-scroll-parallax
 * @remarks
 *
 * I18N
 * a)
 * Procedeu-se a alteração da localização original dos ficheiros do package,
 * i18n.js e LanguageSelector.js foram relocalizados p nova localização
 * src/components/i18n (versão original encontram-se em directoria /src/)
 * src/template/ (versão original encontram-se em directoria /src/)
 *
 * b)
 * Nesta biblioteca é possível a utilização de EXPRESSÕES traduzidas recorrendo
 * para tal aos ficheiros de traduções em /public/assets/i19n/translations/...
 * Existe a possibilidade de extracção destas expressões em formato string e/ou
 * objectos sendo que, para este caso em particular, torna-se necessário proceder
 * à configuração da entrada do ficheiro de configs do i18n p que o método t(...)
 * devolvida objectos e não apenas strings, caso contrário ao tentar obter objectos
 * será devolvido um erro e não executa a tradução do elemento.
 *
 * Para extracção de objectos
 * - Através da config do método => t('array', { returnObjects: true });
 * - Entrada de configuração no ficheiros de configs => returnObjects: true
 *
 */

/**
 * procedimento de importação de estrutura básica da App e da
 * libraria usada para a tradução de conteúdos...
 */
import Dashboard from "./components/Dashboard"; // estrutura base da App
import "./components/i18n/i18n";
//import { Helmet } from "react-helmet";

function App() {
  return (
    <>
      {/* <Helmet>
        <title>Prilux Development</title>
        <meta name="description" content="WebSite description" />
        <meta property="og:title" content="Prilux Release 7.01" />
        <meta property="og:image" content="Your path/to/image.jpg" />
      </Helmet> */}
      <Suspense fallback={null}>
        <Dashboard />
      </Suspense>
    </>
  );
}

export default App;

/*
ELIMINAR DO ORIGINAL
import logo from "./logo.svg";
import "./App.css";

<div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
*/
