import React, { Component, Fragment, isValidElement } from "react";
import { withTranslation } from "react-i18next";
import $ from "jquery";

import RestDataSource from "../../components/webservices/RestDataSource";

import {
  insertMediaWithAppAbsPath,
  extractElementsFromArrayByIsType,
} from "../../Toolbox";

import AppButton from "../AppButton";

import ReactHtmlParser from "react-html-parser";

import "../../scss/GreenHouseServiceDetailView.scss";
import {
  Parallax,
  ParallaxBanner,
  ParallaxProvider,
} from "react-scroll-parallax";
/**
 *
 * View para Estufas Produto do tipo ServiçosView
 * este caso aplica-se aos revestimentos, acessos e climatização,
 * priclima, sistemas auxiliares e complementos
 *
 **/
class GreenHouseServiceDetailView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: null,
      data: {},
    };

    this.t = this.props.t; //tradutor
    this.i18n = null;

    this.logicLatch = false; //TRINCO LÓGICO - FALSE aberto | TRUE fechado

    this.optionId = undefined; //ID da CATEGORIA dos artigos
    this.optionSlug = undefined; //respectivo slug

    if (
      this.props.match &&
      this.props.match.params &&
      "optionId" in this.props.match.params &&
      "optionSlug" in this.props.match.params
    ) {
      this.optionId = this.props.match.params.optionId;
      this.optionSlug = this.props.match.params.optionSlug;
    }

    this.webserver = new RestDataSource(); // para comunicação com a API (REST)
    this.restURI = `rest/estfas-servicos/content/`; // para utilização nos pedidos de dados à API / URI do pedido
  }

  /**
   * @description
   * Para inicialização dos componentes e afins após o seu carregamento no DOM
   */
  componentDidMount() {
    window.isFristTimeLoad = true;

    window.sessionStorage.removeItem("stickyBFM");
    this.t = this.props.t; // p utilização nas acções de tradução
    this.i18n = this.props.i18n;

    let { lang } = this.state;
    lang = this.i18n.language;

    this.setState({ lang });

    this.logicLatch = true;
    // executa pedido à API para fabricar o menu lateral esquerdo...
    this.getDataFromAPI(this.makeURI());
  }

  /**
   * @description
   * Verifica se existem alterações nas props e/ou state e executa
   * as necessárias alterações em conformidade, exemplo, quando se
   * altera o idioma do site poderá ser necessário obter novos termos
   * no novo idioma para preenchimento do layout...
   * caso se verifique que os mesmos já existam dever-se-à evitar a
   * utilização deste recurso, isto porque, poderá estar a criar um
   * conjunto de pedidos desnecessários e que eventualmente tornarão
   * esta solução mais lenta
   *
   * @param {object} prevProps
   * @param {object} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    let { lang } = prevState;

    if (!window.isFristTimeLoad) {
      return;
    }

    let oldUrl = window.sessionStorage.getItem("oldUrl")
        ? window.sessionStorage.getItem("oldUrl")
        : prevProps.match.url,
      newUrl = window.location.href,
      isSameUrl = true;
    if (!newUrl.includes(oldUrl) || window.isFristTimeLoad) {
      isSameUrl = false;
    }
    var onwNavBar = this.props.navData;
    if (onwNavBar === undefined) {
      onwNavBar = JSON.parse(window.sessionStorage.getItem("navigation"));
    }

    if (lang !== this.i18n.language) {
      lang = this.i18n.language;

      this.setState({ lang }, () => {
        //idioma alterado, obtem os dados da API no novo idioma
        if (this.logicLatch === false) {
          //fecha o trinco para efeitos de acesso ao pedido de
          //novos conteúdos à API
          this.logicLatch = true;
          //executa o pedido
          this.getDataFromAPI(this.makeURI()); //(requestURI);
        }
      });
    }

    //para o menu lateral
    if (
      this.props.navData &&
      "ghouse" in this.props.navData &&
      (this.state.stickyBFM === null ||
        JSON.stringify(this.props.navData.ghouse) !==
          JSON.stringify(this.state.stickyBFM))
    ) {
      let { stickyBFM } = this.state;

      if ("ghouse" in this.props.navData) {
        stickyBFM = this.props.navData.ghouse;
        this.setState({ stickyBFM }, () => {
          if (this.state.data != null && this.state.data.content) {
            let navElems = this.state.data.content.map((val) => {
              return {
                id: val.category[0].id,
                text: val.category[0].title,
                uri: `${this.t("menu").ghouse[1]}-${
                  this.t("menu").services[1]
                }/${this.optionId}/${this.optionSlug}/${val.category[0].id}/${
                  val.slug
                }/#f${val.category[0].id}`,
                hash: `#f${val.category[0].id}`,
                state: { GreenHouseServiceDetailView: true },
              };
            });
            window.isFristTimeLoad = false;
            this.props.setSFMOptions(navElems);
            window.sessionStorage.setItem(
              "stickyBFM",
              JSON.stringify(navElems)
            );
          }
        });

        /**Mudança de seção durante o scroll */
        $.fn.isInViewport = function () {
          var elementTop = $(this).offset().top;
          var elementBottom = elementTop + $(this).outerHeight();

          var viewportTop = $(window).scrollTop();
          var viewportBottom = viewportTop + $(window).height();

          return elementBottom > viewportTop && elementTop < viewportBottom;
        };

        $(window).on("resize scroll", function () {
          $(".each-ghouse-service").each(function () {
            if ($(this).isInViewport()) {
              var _self_id = $(this).attr("id");
              _self_id = _self_id.replace("f", "");

              $(".sticky-fm li").removeClass("active");
              $("#" + _self_id + "sticky").addClass("active");
            }
          });
        });
      }
    }
  }

  makeURI() {
    let requestURI = `${this.i18n.language}/${this.restURI}`;

    // verifica se existe optionId e optionSlug e acrescenta-os ao URI
    if (this.optionId !== undefined && this.optionSlug !== undefined) {
      requestURI += `${this.optionId}`;
    }

    return requestURI;
  }

  getDataFromAPI = (requestURL) => {
    this.webserver.getData(requestURL, (result, xhr) => {
      this.logicLatch = false;

      if (xhr.status === 200 && "data" in xhr && typeof xhr.data === "object") {
        let { data } = this.state;
        data = result;

        this.setState({ data });
        this.handleStickyMenuItemClick();
      }
    });
  };

  /**
   * @description
   * Quando existe um link através de menu principal,
   * procura pela ancora correspondente
   * e faz scroll até chegar ao destino
   */
  handleStickyMenuItemClick = (e) => {
    var element = this.props.match.params.familyId;
    var ele = document.getElementById("f" + element);
    window.scrollTo(0, ele.offsetTop - 300);
  };

  makeBody = () => {
    let body = null;

    if (this.state.data !== null && this.state.data.content) {
      body = this.state.data.content.map((val, key) => {
        let linkUrl =
          Array.isArray(val.links) && val.links.length > 0 ? val.links[0] : "";

        let img_generic = extractElementsFromArrayByIsType(val.imgs, "generic");
        let img_thumbnail = extractElementsFromArrayByIsType(
          val.imgs,
          "thumbnail"
        );
        let img_gallery = extractElementsFromArrayByIsType(val.imgs, "gallery");

        let backgroundGeneric,
          backgroundThumbnail,
          backgroundGallery = [];

        backgroundGeneric =
          img_generic !== null
            ? insertMediaWithAppAbsPath(img_generic[0].name, "imgs")
            : "";

        backgroundThumbnail =
          img_thumbnail !== null
            ? insertMediaWithAppAbsPath(img_thumbnail[0].name, "imgs")
            : "";

        backgroundGallery =
          img_gallery !== null
            ? insertMediaWithAppAbsPath(img_gallery[0].name, "imgs")
            : "";

        var slog = null;
        if (val.imgs.length !== 0) {
          slog = [val.imgs[0].title, val.imgs[0].subTitle];
        } else {
          slog = ["", ""];
        }

        if (val.category !== undefined) {
          var nameCategory = val.category[0].parentID;
          var idCategory = val.category[0].id;
        }

        return (
          <div /* className="serviceUnit" */
            className={`ghouseUnit ${
              key % 2 === 0 && key > 0 ? "ghouseUnitGray" : ""
            }`}
            key={val.id}
            /*  id={"f" + idCategory} */
          >
            <div className="each-ghouse-service" id={"f" + idCategory}></div>
            <div
              className={`imageThird ${
                backgroundGallery.length === 0 ? "block" : ""
              }`}
              style={{ backgroundImage: "url(" + backgroundGallery + ")" }}
            >
              <div className="line"></div>
              <div
                className={`eachElement ${
                  key % 2 === 0 && key > 0 ? "eachElementGray" : ""
                }`}
              >
                {" "}
                <div className="conteudoText">
                  <h3 className="category_section">{nameCategory}</h3>
                  <h1 className="title_section">
                    {ReactHtmlParser(val.title.split())}
                  </h1>
                  <div className="description_section">
                    {ReactHtmlParser(val.description)}
                  </div>
                  <button
                    type="button"
                    /* className="btn_more" */ className={`btn_more ${
                      linkUrl.length === 0 ? "d-none" : ""
                    }`}
                  >
                    <a href={linkUrl.url}>
                      <AppButton type="" className="" text={this.t("more")} />
                    </a>
                  </button>
                </div>
              </div>
              <Fragment>
                <div
                  className={`conteudoMedia ${
                    backgroundThumbnail.length === 0 ? "noExtra" : ""
                  }`}
                >
                  <div
                    className={`imageFirst ${
                      backgroundGeneric.length === 0 ? "d-none" : ""
                    }`}
                    style={{
                      backgroundImage: "url(" + backgroundGeneric + ")",
                    }}
                  ></div>
                  <div
                    className={`imageSecond ${
                      backgroundThumbnail.length === 0 ? "d-none" : ""
                    }`}
                    style={{
                      backgroundImage: "url(" + backgroundThumbnail + ")",
                    }}
                  ></div>
                </div>
              </Fragment>
              <div
                className={`wordSpace ${
                  /* slog !== undefined || */ slog[0] !== "" ? "" : "d-none"
                }`}
              >
                <ParallaxProvider>
                  <Parallax
                    className="custom-class"
                    x={[-25, 40]}
                    tagOuter="figure"
                  >
                    <h1 className="word_title">
                      {slog !== null ? slog[0] : null}
                      <Parallax
                        className="custom-class"
                        x={[40, -40]}
                        tagOuter="figure"
                      >
                        <span
                          className={`wordStroke ${
                            slog !== null &&
                            slog.length >= 2 &&
                            !slog[0].length - 1
                              ? "wordStroke2"
                              : "" || (slog !== null && slog.slice(-1)[0]) //slog[0].length - 1
                              ? "wordStroke3"
                              : ""
                          }`}
                        >
                          {slog !== null ? slog.slice(1).join(" ") : null}
                        </span>
                      </Parallax>
                    </h1>
                  </Parallax>
                </ParallaxProvider>
              </div>
              {/*  <div className="line"></div> */}
            </div>
          </div>
        );
      });
    }
    return body;
  };

  render() {
    return (
      <div className="body_greenHouse">
        {this.state.data !== undefined ? this.makeBody() : null}
        <p className="phrase_side">
          {
            this.optionSlug === this.props.t("views").greenhouse.revestimentos
            ? this.props.t("views").greenhouse.slogan_Service_Alternative
            : this.props.t("views").greenhouse.slogan_Service}
          {/* {this.props.t("views").greenhouse.slogan_Service} */}
        </p>
      </div>
    );
  }
}

export default withTranslation()(GreenHouseServiceDetailView);
