import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import RestDataSource from "../../components/webservices/RestDataSource";
import $ from "jquery";
import ReactHtmlParser from "react-html-parser";

import {
  extractElementsFromArrayByIsType,
  insertMediaWithAppAbsPath,
  Configs,
} from "../../Toolbox";

import "../../scss/GreenHouseListView.scss";


/**
 *
 * View para listar todas as estufas do tipo produtos do tipo componentes.
 * No menu lateral esquerdo, lista tudo relativo às estufas,
 * desde das estufas, a estufas do tipo produtos, a estufas do tipo serviços
 *
 * o Click das estufas e da estufas do tipo serviços direciona para o
 * respectivo layout
 **/
class GreenHouseListView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: null,
      data: {},
      menu: null, // para armazenamento do componentes de navegação...
    };

    this.t = this.props.t; //tradutor
    this.i18n = null;
    this.newData = null;
    this.logicLatch = false; //TRINCO LÓGICO - FALSE aberto | TRUE fechado

    this.optionId = undefined; //ID da CATEGORIA dos artigos
    this.optionSlug = undefined; //respectivo slug

    if (
      this.props.match &&
      this.props.match.params &&
      "optionId" in this.props.match.params &&
      "optionSlug" in this.props.match.params
    ) {
      this.optionId = this.props.match.params.optionId;
      this.optionSlug = this.props.match.params.optionSlug;
    }

    this.webserver = new RestDataSource(); // para comunicação com a API (REST)
    this.restURI = `rest/product-list/content/`; // para utilização nos pedidos de dados à API / URI do pedido
  }

  /**
   * @description
   * Para inicialização dos componentes e afins após o seu carregamento no DOM
   */
  componentDidMount() {
    window.isFristTimeLoad = true;
    window.sessionStorage.removeItem("stickyBFM");
    this.t = this.props.t; // p utilização nas acções de tradução
    this.i18n = this.props.i18n;

    let { lang } = this.state;
    lang = this.i18n.language;

    this.setState({ lang });

    this.logicLatch = true;
    // executa pedido à API para fabricar o menu lateral esquerdo...
    this.getDataFromAPI(this.makeURI());
  }

  /**
   * @description
   * Verifica se existem alterações nas props e/ou state e executa
   * as necessárias alterações em conformidade, exemplo, quando se
   * altera o idioma do site poderá ser necessário obter novos termos
   * no novo idioma para preenchimento do layout...
   * caso se verifique que os mesmos já existam dever-se-à evitar a
   * utilização deste recurso, isto porque, poderá estar a criar um
   * conjunto de pedidos desnecessários e que eventualmente tornarão
   * esta solução mais lenta
   *
   * @param {object} prevProps
   * @param {object} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    let { lang } = prevState;

    let isSameUrl = false;
    var onwNavBar = this.props.navData;

    if (!window.isFristTimeLoad) {
      return;
    }

    this.optionId = this.props.match.params.optionId;
    this.optionSlug = this.props.match.params.optionSlug;


    if (lang !== this.i18n.language) {
      lang = this.i18n.language;

      this.setState({ lang }, () => {
        //idioma alterado, obtem os dados da API no novo idioma

        this.getDataFromAPI(this.makeURI()); //(requestURI);
        let oldUrl = window.sessionStorage.getItem("oldUrl")
            ? window.sessionStorage.getItem("oldUrl")
            : prevProps.match.url,
            newUrl = window.location.href,
            isSameUrl = true;
        if (!newUrl.includes(oldUrl) || window.isFristTimeLoad) {
          isSameUrl = false;
        }

        if (onwNavBar === undefined) {
          onwNavBar = JSON.parse(window.sessionStorage.getItem("navigation"));
        }
      });
    }


    /**
     * @description
     * StickyMenu
     */
    if (
      this.props.navData &&
      "ghouse" in this.props.navData &&
      (this.state.stickyBFM === null ||
        !isSameUrl)
    ) {
      let { stickyBFM } = this.state;
      window.isFristTimeLoad = false;
      if ("ghouse" in this.props.navData) {
        stickyBFM = this.props.navData.ghouse;
        this.setState({ stickyBFM }, () => {
          //let elementFind = this.optionSlug;
          let elementFind = this.optionId;
          var found = stickyBFM.filter(function (element) {
            //return val.slug === elementFind;
            return element.id === elementFind;
          });

          if (
            "familyId" in this.props.match.params &&
            "familySlug" in this.props.match.params
          ) {
            this.familyId = this.props.match.params.optionId;
            this.familySlug = this.props.match.params.optionSlug;
          }

          if (
            found &&
            found.length !== 0 &&
            Array.isArray(found) !== undefined
          ) {
            let navElems = found[0].offspring.map((val) => {
              return {
                id: val.id,
                text: val.title,
                uri: `${this.t("menu").ghouse[1]}/${this.familyId}/${
                  this.familySlug
                }/${val.id}/${val.slug}/#f${val.id}`,
                hash: `#f${val.id}`,
                state: { GreenHouseListView: true },
                onClick: (e) => {
                  e.preventDefault();

                  const target = e.target;
                  const href = target.href;
                  let _goTo = target.href.substring(
                    target.href.indexOf("#f") + 1
                  );

                  _goTo = document.getElementById(_goTo);

                  if (_goTo !== undefined) {
                    _goTo.scrollIntoView({
                      behavior: "smooth",
                    });
                  }
                },
              };
            });
            this.props.setSFMOptions(navElems);
            window.sessionStorage.setItem(
              "stickyBFM",
              JSON.stringify(navElems)
            );
          }
        });

        /**Mudança de seção durante o scroll */
        $.fn.isInViewport = function () {
          var elementTop = $(this).offset().top;
          var elementBottom = elementTop + $(this).outerHeight();

          var viewportTop = $(window).scrollTop();
          var viewportBottom = viewportTop + $(window).height();

          return elementBottom > viewportTop && elementTop < viewportBottom;
        };
        $(window).on("resize scroll", function () {
          $(".each-category-ghouse-product").each(function () {
            if ($(this).isInViewport()) {
              // do something

              var _self_id = $(this).attr("id");
              _self_id = _self_id.replace("f", "");
              $(".sticky-fm li").removeClass("active");
              $("#" + _self_id + "sticky").addClass("active");
              window.history.pushState(
                null,
                null,
                $("#" + _self_id + "sticky a").attr("href")
              );
            }
          });
        });

        var isCollidedToSubMenu = false;
        var isCollidedToSubMenuOffSet = 100000;

        var isCollidedToSticky = false;
        var isCollidedOffSetToSticky = 100000;

        $(window).on("resize scroll", function () {
          let $menuLateral = $(".menu-lateral");
          let $stickyMenu = $(".sticky-fm");
          let $appFooter = $(".app-footer");

          //Deteção de Colisão no MenuLateral
          if ($menuLateral.isColliding($(".app-footer"), -55)) {
            if (!isCollidedToSubMenu) {
              isCollidedToSubMenu = true;
              isCollidedToSubMenuOffSet = $(window).scrollTop();
            }
            let fixedSize =
              $appFooter.offset().top - $menuLateral.outerHeight() - 55 + "px";
            $menuLateral.css({
              position: "absolute",
              top: fixedSize,
            });
          }

          //StickyMenu
          if ($stickyMenu.isColliding($(".app-footer"))) {
            if (!isCollidedToSticky) {
              isCollidedToSticky = true;
              isCollidedOffSetToSticky = $(window).scrollTop();
            }
            let fixedSize =
              $appFooter.offset().top - $stickyMenu.outerHeight() + "px";
            $stickyMenu.css({
              position: "absolute",
              top: fixedSize,
            });
          }

          if (isCollidedToSubMenuOffSet >= $(window).scrollTop()) {
            $menuLateral.css({
              position: "fixed",
              top: "110px",
            });
          }

          if (isCollidedOffSetToSticky >= $(window).scrollTop()) {
            $stickyMenu.css({
              position: "fixed",
              top: "110px",
            });
          }

          $(".container-d-coisas").each(function () {
            if ($(this).isInViewport()) {
              // do something
              var _self_id = $(this).attr("id");
              _self_id = _self_id.replace("f", "");
              $(".sticky-fm li").removeClass("active");
              $("#" + _self_id + "sticky").addClass("active");
            }
          });
        });
      } else {
        return null;
      }
    }
  }

  /**
   * @description
   *  Método responsável para fazer o pedido de dados à API
   *
   */
  makeURI() {
    let requestURI = `${this.i18n.language}/${this.restURI}`;

    // verifica se existe optionId e optionSlug e acrescenta-os ao URI
    if (this.optionId !== undefined && this.optionSlug !== undefined) {
      requestURI += `${this.optionId}`;
    }

    return requestURI;
  }

  /**
   * @description
   * Método responsável por usar o pedido de dados à API... a usar nas life cycle
   * componentDidMount e/ou componentDidUpdate
   */
  getDataFromAPI = (requestURL) => {
    this.webserver.getData(requestURL, (result, xhr) => {
      this.logicLatch = false;

      if (xhr.status === 200 && "data" in xhr && typeof xhr.data === "object") {
        let { data } = this.state;
        data = result;
        this.newData = data;
        this.setState({ data });
        setTimeout(this.handleStickyMenuItemClick, 1000);

      }
    });
  };

  /**
   * @description
   * Quando existe um link através de menu principal,
   * procura pela ancora correspondente
   * e faz scroll até chegar ao destino
   */
  handleStickyMenuItemClick = () => {
    var element = this.props.match.params.familyId;
    var ele = document.getElementById("f" + element);

    window.scrollTo({top: ele.offsetTop, behavior: "smooth"});
  };

  /**
   * @description
   * Abrir o menu de categoria de estufas,
   * estufas/serviços e estufas/produtos
   * apenas em dispositivos móveis
   * --> situado no lado esquerdo
   */
  openMenuGHouseProduct = (e) => {
    e.preventDefault();
    e.stopPropagation();

    var menu = document.querySelectorAll("div .menu-lateral");
    menu[0].style.left = "0px";

    var iconMenu = document.querySelectorAll("div .menuGHouseProducts");
    iconMenu[0].style.left = "-40px";
  };

  /**
   * @description
   * Fechar o menu de categoria de estufas,
   * estufas/serviços e estufas/produtos
   * apenas em dispositivos móveis
   * --> situado no lado esquerdo
   */
  closeMenuGHouseProduct = (e) => {
    e.preventDefault();
    e.stopPropagation();

    var menu = document.querySelectorAll("div .menu-lateral");
    menu[0].style.left = "-600px";

    var iconMenu = document.querySelectorAll("div .menuGHouseProducts");
    iconMenu[0].style.left = "15px";
  };

  /**
   * @description
   * Menu de categorias de estufas,
   * estufas/serviços e estufas/produtos
   * Menu situado na lateral esquerda
   *
   */
  makeMenuFamily = () => {
    const { lang } = this.state;
    const newMenu = this.state.stickyBFM;
    return (
      <div className="col-lg-2 menu-lateral">
        <div
          className="closeMenu d-lg-none"
          style={{ backgroundImage: "url(/assets/imgs/close.png)" }}
          onClick={this.closeMenuGHouseProduct}
        ></div>

        <ul className="listGreenHouse">
          {newMenu
            ? newMenu.map((val, key) => {
                let urlUri = `${this.t("menu").ghouse[1]}`;
                let originalurlUri = urlUri;
                urlUri = originalurlUri;

                urlUri =
                  val.code.trim().length > 0
                    ? `${urlUri}-${this.t("menu").services[1]}`
                    : originalurlUri;

                /**
                 * @description
                 * 2ªparte do url
                 * caso exista offspring(ex: componentes), mostra o
                 * 1º elemento do array do offspring
                 *
                 */
                var componentURL = "";
                if ("offspring" in val && val.offspring && val.offspring.length > 0) {
                  let firstComponent = val.offspring[0];

                  componentURL = `${firstComponent.id}/${firstComponent.slug}/#f${firstComponent.id}`;
                }

                /*
                 * verifica se a categoria provinda do pedido à BD( traz os dados )
                 * é compatível com a categoria selecionada no MenuProducts
                 * adiciona a class active para efeito de selecionado
                 */
                let thisIsIt = null;

                if (val.title === "Componentes") {
                  thisIsIt = "active";
                }

                /* if (window.location.href.indexOf(componentURL) >= 0) {
                  thisIsIt = "active";
                }*/
                return (
                  <li
                    className={thisIsIt !== null ? thisIsIt : ""}
                    key={val.id}
                  >
                    <a
                      href={`/${lang}/${urlUri}/${val.id}/${val.slug}/${componentURL}`}
                    >
                      {val.title}
                    </a>
                  </li>
                );
              })
            : null}
        </ul>
      </div>
    );
  };

  makeSubCategoryProduct = () => {
    let subCategory = null;
    if (this.state.data !== null && this.state.data.content) {
      subCategory = this.state.data.content.map((val) => {
        // obtem imagem do banner
        let img = extractElementsFromArrayByIsType(val.imgs, "thumbnail");

        // valida resultado obtido e gera o link absoluto da imagem
        img =
          img !== null ? insertMediaWithAppAbsPath(img[0].name, "imgs") : null;

        let alt =
          val.imgs[0] !== null && val.imgs[0] !== undefined
            ? val.imgs[0].alt
            : null;

        return (
          <div
            key={val.id}
            className="eachElementOfGHouse" /* id={"f" + val.id} */
          >
            <div
              className="each-category-ghouse-product"
              id={"f" + val.id}
            ></div>
            <div className="detail_ghouse_selec">
              <div className="conteudo_ghouseProduct">
                <h6 className="cat_product">
                  {val.parentID !== undefined && val.parentID.length > 0
                    ? val.parentID[0].title
                    : ""}
                </h6>
                <h1 className="title_ghouseProduct">{val.title}</h1>

                <div className="desc_ghouseProduct">
                  {val.features !== undefined && val.features.length > 0
                    ? val.features[0].value
                    : ""}
                </div>
                <div className="line_ghouseProduct"></div>
              </div>
            </div>
            <img
              alt={alt}
              className={img !== null ? "img_ghouseProduct_1" : "d-none"}
              src={img !== null ? img : null}
            />

            <div className="product-list">
              {"productlist" in val &&
              Array.isArray(val.productlist) &&
              val.productlist.length > 0
                ? this.makeProduct(val.productlist)
                : ""}
            </div>
          </div>
        );
      });
    }
    return subCategory;
  };

  /**
   * @description: Listagem de estufas/produtos associados à subcategoria Componentes
   *
   * @param {array} productList -> lista de produtos a apresentar por categoria / FAMILIA
   */
  makeProduct = (productList = []) => {
    let product = null;
    if (this.state.data !== null && this.state.data.content) {
      product = productList.map((val) => {
        // obter imagem do tipo ICON
        let img = extractElementsFromArrayByIsType(val.imgs, "icon");

        // valida resultado obtido e gera o link absoluto da imagem
        img =
          img !== null ? insertMediaWithAppAbsPath(img[0].name, "imgs") : null;

        let alt =
          val.imgs[0] !== null && val.imgs[0] !== undefined
            ? val.imgs[0].alt
            : null;

        return (
          <div
            key={val.id}
            className="card col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"
          >
            <a
              href={
                Configs.base_url +
                this.state.lang +
                "/" +
                this.t("menu").product_detalhe[1] +
                "/" +
                val.id +
                "/" +
                val.slug
              }
            >
              <img
                className="card-img-top"
                /* className={img !== null ? "card-img-top" : "d-none"} */
                src={img !== null ? img : null}
                alt={alt !== null ? alt : "estufa"}
              />
              <div className="card-body">
                <h1 className="card-text">{val.title}</h1>
              </div>
            </a>
          </div>
        );
      });
    }
    return product;
  };

  render() {
    return (
      <div className="body_ghouse">
        <img
          id="pageLoader"
          className="loader"
          src="/assets/imgs/a-loader-2.gif"
        />
        <div
          className="menuGHouseProducts d-lg-none"
          onClick={this.openMenuGHouseProduct}
        >
          <span className="m_1"></span>
          <span className="m_2"></span>
          <span className="m_3"></span>
        </div>
        {/*  Páginas da Listagem de Estufas -- View Criada */}
        {this.state.data !== undefined ? this.makeMenuFamily() : null}

        <div className="col-12 col-lg-10 familyGreenHouse">
          {this.state.data !== undefined ? this.makeSubCategoryProduct() : null}
        </div>
      </div>
    );
  }
}

export default withTranslation()(GreenHouseListView);
