import React, { useState, useEffect } from "react";
import { First } from "react-bootstrap/esm/PageItem";
import { useTranslation } from "react-i18next";

import "../scss/FinancingSystems.scss";

/**
 *
 */
const FianacingSystems = (props) => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState();

  useEffect(() => {
    // Actualiza o idioma usado na app... esta var poderá servir como
    // Lifting Up State e por esta via implicar o render dos components
    // child. Neste momento julga-se últil para obter os conteúdos
    // dinâmicos a partir de uma API ou BO
    setLang(i18n.language);
  }, [lang, setLang]);

  const assets = "/assets/docs/";

  //Certificado_SGS.pdf
  const finSystems = [
    /*  ["Compete2020.png", "Compete_2020", `${assets}Digital 2020.pdf`],
    ["Portugal2020.png", "Portugal_2020", `${assets}Digital 2020.pdf`],
    ["Fundo_EU.png", "Eu 2020", `${assets}Digital 2020.pdf`],
    ["Centro2020.png", "Centro_2020", `${assets}PRILUX_SI_Inov_Prod.pdf`],
    ["PME_Lider.png", "PME_Lider", ``],
    ["Certificacao_sgs.png", "SGS", `${assets}Certificado_SGS.pdf`], */

    [
      "Compete2020.png",
      "Compete_2020",
      `/${lang}/${t("menu").funded_projects[1]}`,
      "_self",
    ],
    [
      "Portugal2020.png",
      "Portugal_2020",
      `/${lang}/${t("menu").funded_projects[1]}`,
      "_self",
    ],
    [
      "Fundo_EU.png",
      "Eu 2020",
      `/${lang}/${t("menu").funded_projects[1]}`,
      "_self",
    ],

    ["PME_Lider.png", "PME_Lider", ``, "_blank"],
    ["Certificacao_sgs.png", "SGS", `${assets}Certificado_SGS.pdf`, "_blank"],
  ];

  return (
    <div className="f-systems row">
      <ul>
        {finSystems.map((val) => {
          return (
            <li key={val[0]} className="col-2">
              <a
                href={val[2] !== `` ? `${val[2]}` : null}
                /* target="_blank" */

                target={val[3]}
                rel="noopener noreferrer"
              >
                {
                  <img
                    className={`img-fluid ${val[1]}`}
                    src={`/assets/imgs/${val[0]}`}
                    alt={val[1]}
                  />
                }
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FianacingSystems;
