import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import $ from "jquery";

import RestDataSource from "../../components/webservices/RestDataSource";
import {
  Configs,
  insertMediaWithAppAbsPath,
  extractElementsFromArrayByIsType,
} from "../../Toolbox";

import ReactHtmlParser from "react-html-parser";

import "../../scss/AutomatismsView.scss";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";

import ShareSocialMedia from "../ShareSocialMedia";

class AutomatismsView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: null,
      data: {},
    };

    this.t = props.t; // tradutor
    this.i18n = null;

    this.logicLatch = false; // TRINCO LÓGICO - FALSE aberto | TRUE fechado

    this.optionId = undefined; // ID da CATEGORIA dos artigos
    this.optionSlug = undefined; // respectivo slug

    //verifica se o match existe e retira a origem da VIEW
    if (
      this.props.match &&
      this.props.match.params &&
      "optionId" in this.props.match.params &&
      "optionSlug" in this.props.match.params
    ) {
      this.optionId = this.props.match.params.optionId;
      this.optionSlug = this.props.match.params.optionSlug;
    }

    this.webserver = new RestDataSource(); // para comunicação com a API (REST)
    this.restURI = `rest/automatismos/content/`; // para utilização nos pedidos de dados à API / URI do pedido
  }

  /**
   * @description
   * Para inicialização dos componentes e afins após o seu carregamento no DOM
   */
  componentDidMount() {
    window.isFristTimeLoad = true;
    window.sessionStorage.removeItem("stickyBFM"); //limpeza das variáveis em SessionStorage
    this.t = this.props.t; // p utilização nas acções de tradução
    this.i18n = this.props.i18n;

    let { lang } = this.state;
    lang = this.i18n.language;

    this.setState({ lang });

    //const requestURI = `${this.i18n.language}/${this.restURI}/${this.optionId}`;

    this.logicLatch = true;
    this.getDataFromAPI(this.makeURI());
  }

  /**
   * @description
   * Verifica se existem alterações nas props e/ou state e executa
   * as necessárias alterações em conformidade, exemplo, quando se
   * altera o idioma do site poderá ser necessário obter novos termos
   * no novo idioma para preenchimento do layout...
   * caso se verifique que os mesmos já existam dever-se-à evitar a
   * utilização deste recurso, isto porque, poderá estar a criar um
   * conjunto de pedidos desnecessários e que eventualmente tornarão
   * esta solução mais lenta
   *
   * @param {object} prevProps
   * @param {object} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    let { lang } = prevState;
    let isSameUrl = false;
    var onwNavBar = this.props.navData;

    if (!window.isFristTimeLoad) {
      return;
    }
    //let requestURI = this.makeURI();
    //novo idioma seleccionado, actualiza-o
    if (lang !== this.i18n.language) {
      lang = this.i18n.language;

      this.setState({ lang }, () => {
        //idioma alterado, obtem os dados da API no novo idioma
     //   if (this.logicLatch === false) {
          //fecha o trinco para efeitos de acesso ao pedido de
          //novos conteúdos à API
         // this.logicLatch = true;
          //executa o pedido
          this.getDataFromAPI(this.makeURI()); //(requestURI);
          let oldUrl = window.sessionStorage.getItem("oldUrl")
              ? window.sessionStorage.getItem("oldUrl")
              : prevProps.match.url,
              newUrl = window.location.href,
              isSameUrl = true;
          if (!newUrl.includes(oldUrl) || window.isFristTimeLoad) {
            isSameUrl = false;
          }
          if (onwNavBar === undefined) {
            onwNavBar = JSON.parse(window.sessionStorage.getItem("navigation"));
          }
        //}
      });
    }


    /** stickyMenu**/
    if (
      onwNavBar &&
      "automatisms" in onwNavBar &&
      (this.state.stickyBFM === null ||
        !isSameUrl)) {
      let { stickyBFM } = this.state;

      if ("automatisms" in onwNavBar) {
        stickyBFM = onwNavBar.automatisms;

        this.setState({ stickyBFM }, () => {
          let navElems = stickyBFM.map((val) => {
            return {
              id: val.id,
              text: val.title,
              uri: `${this.t("menu").automatisms[1]}/${val.id}/${val.slug}/#f${
                val.id
              }`,
              hash: `#f${val.id}`,
              state: { AutomatismsView: true },
            };
          });

          window.isFristTimeLoad = false;
          this.props.setSFMOptions(navElems);
          window.sessionStorage.setItem("stickyBFM", JSON.stringify(navElems));
        });

        /**Mudança de seção durante o scroll */
        $.fn.isInViewport = function () {
          var elementTop = $(this).offset().top;
          var elementBottom = elementTop + $(this).outerHeight();

          var viewportTop = $(window).scrollTop();
          var viewportBottom = viewportTop + $(window).height();

          return elementBottom > viewportTop && elementTop < viewportBottom;
        };

        $(window).on("resize scroll", function () {
          $(".each-category-automatism").each(function () {
            if ($(this).isInViewport()) {
              // do something
              var _self_id = $(this).attr("id");
              _self_id = _self_id.replace("f", "");
              $(".sticky-fm li").removeClass("active");
              $("#" + _self_id + "sticky").addClass("active");
            }
          });
        });
      }
    }
  }

  /**
   * @description
   *  Método responsável para fazer opedido de dados à API
   *
   */
  makeURI() {
    let requestURI = `${this.i18n.language}/${this.restURI}`;

    // verifica se existe optionId e optionSlug e acrescenta-os ao URI
    if (this.optionId !== undefined && this.optionSlug !== undefined) {
      requestURI += `${this.optionId}`;
    }

    return requestURI;
  }

  /**
   * @description
   * Método responsável por usar o pedido de dados à API... a usar nas life cycle
   * componentDidMount e/ou componentDidUpdate
   */
  getDataFromAPI = (requestURL) => {
    this.webserver.getData(requestURL, (result, xhr) => {
      this.logicLatch = false;

      if (xhr.status === 200 && "data" in xhr && typeof xhr.data === "object") {
        let { data } = this.state;
        data = result;

        this.setState({ data });
        this.handleStickyMenuItemClick();
      }
    });
  };

  /**
   * @description
   * Quando existe um link através de menu principal,
   * procura pela ancora correspondente
   * e faz scroll até chegar ao destino
   */
  handleStickyMenuItemClick = (e) => {
    var element = this.props.match.params.optionId;

    var ele = document.getElementById("f" + element);
    ele.scrollIntoView({
      behavior: "smooth",
    });
  };

  /**
   * @description
   *
   */
  makeHeaderAuto = (key) => {
    if (
      this.state.data !== null &&
      this.state.data.content.all[parseInt(key)]
    ) {
      let val = this.state.data.content.all[parseInt(key)];

      let backgroundImg = [];
      let img_generic = extractElementsFromArrayByIsType(val.imgs, "thumbnail");

      backgroundImg[0] =
        img_generic !== null
          ? insertMediaWithAppAbsPath(img_generic[0].name, "imgs")
          : "";

      /**
       * Verifica se existe mais de 1 thumbnail no array img_generic
       * caso contrário chega à class e fica como display:none
       **/
      if (
        img_generic &&
        img_generic.length >= 2 &&
        img_generic.length !== null
      ) {
        backgroundImg[1] =
          img_generic !== null
            ? insertMediaWithAppAbsPath(img_generic[1].name, "imgs")
            : "";
      } else {
        backgroundImg[1] = img_generic === null ? "d-none" : "";
      }

      /**
       * Verifica se existe title e/ou subtitle no array
       * caso contrário a class fica com o valor none
       **/
      let slog = [];
      if (val.title && val.title !== null && val.title !== undefined) {
        slog = val.title.split(" ", 2);
      }

      let phrase = this.props.t("views").automatisms.share_product;
      let link = Configs.base_url + this.props.match.url.substring(1);

      let techSheet = [];
      if (
        val.docs &&
        val.docs !== undefined &&
        val.docs !== null &&
        val.docs[0] &&
        val.docs[0].type === "techsheet"
      ) {
        techSheet = insertMediaWithAppAbsPath(val.docs[0].name, "docs");
      }

      return (
        <div className="product_content" key={val.id}>
          <div className="bg_gray_1">
            <div className="text_content">
              <h6 className="category_auto">{this.t("menu").automatisms[0]}</h6>
              <h1 className="name_auto">{val.title}</h1>
              <div className="description_auto">
                {ReactHtmlParser(val.description)}
              </div>
              <a
                href={techSheet} /* className="download_fichaTecnica" */
                className={` ${
                  techSheet.length !== 0 ? "download_fichaTecnica" : "d-none"
                }`}
                download
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/assets/imgs/Seta-03.png" alt="seta" />
                <p>{this.props.t("views").products.catalogo}</p>
              </a>
            </div>
          </div>
          <div className="img_content">
            <div
              className={`first-img ${
                backgroundImg[1] === undefined || backgroundImg[1] === ""
                  ? "only1Image"
                  : ""
              }`}
              style={
                backgroundImg[0] === undefined || backgroundImg[0] === ""
                  ? { display: "none" }
                  : {
                      backgroundImage: "url(" + backgroundImg[0] + ")",
                    }
              }
              role="img"
              /* aria-label={img_generic[0].alt} */
              aria-label={
                img_generic[0] && img_generic[0] !== null
                  ? img_generic[0].alt
                  : null
              }
            ></div>
            <div
              className="last-img"
              style={
                backgroundImg[1] === undefined || backgroundImg[1] === ""
                  ? { display: "none" }
                  : {
                      backgroundImage: "url(" + backgroundImg[1] + ")",
                    }
              }
              role="img"
              /* aria-label={img_generic[1].alt} */
              aria-label={
                img_generic[1] && img_generic[1] !== null
                  ? img_generic[1].alt
                  : null
              }
            ></div>
          </div>

          {this.state.data !== undefined && this.state.data.productsList ? (
            <ShareSocialMedia nameShare={phrase} shareUrl={link} />
          ) : null}

          <div className="word_news">
            <div>
              <ParallaxProvider>
                <Parallax
                  className="custom-class"
                  x={[0, 10]}
                  tagOuter="figure"
                >
                  {slog[0]}
                </Parallax>
                <Parallax
                  className="custom-class"
                  x={[0, -15]}
                  tagOuter="figure"
                >
                  <div className="subtitle">{slog[1]}</div>
                </Parallax>
              </ParallaxProvider>
            </div>
          </div>
        </div>
      );
    }
  };

  makeSimbolos(key) {
    let link = null;
    let caracteristics = null;

    if (
      this.state.data !== null &&
      this.state.data.content.all[key] &&
      this.state.data.content.all !== null &&
      this.state.data.content.all[key].caracteristics
    ) {
      caracteristics = this.state.data.content.all[key].caracteristics.map(
        (val, key) => {
          return (
            <li
              key={val.id}
              style={{
                backgroundImage:
                  "url('" +
                  insertMediaWithAppAbsPath(val.thumbnail, "symbols") +
                  "')",
              }}
            >
              <h1>{ReactHtmlParser(val.title)}</h1>
              <div>{ReactHtmlParser(val.description)}</div>
            </li>
          );
        }
      );
    }

    return caracteristics;
  }

  makeSecondPart(key) {
    let val = this.state.data.content.all[key];

    let img_table = extractElementsFromArrayByIsType(val.imgs, "gallery");

    let backgroundGallery =
      img_table !== null
        ? insertMediaWithAppAbsPath(img_table[0].name, "imgs")
        : "";

    let img_slider = extractElementsFromArrayByIsType(val.imgs, "slider");

    let backgroundSlider =
      img_slider !== null
        ? insertMediaWithAppAbsPath(img_slider[0].name, "imgs")
        : "";

    return (
      <div
        className={`bg_gray_2 ${img_table === "" ? "d-none" : ""}`}
        key={val.id}
      >

        <div className="model_image">
          <img src={backgroundSlider} alt={img_slider[0].alt} />
        </div>
        <div className="model_table">
        <img
            src={backgroundGallery}
            alt={img_table[0].alt}
            className="automatisms_table"
        />
      </div>
      </div>
    );
  }

  makebod = () => {
    let body = this.state.data.content.all.map((val, key) => {
      return (
        <div
          className="body_automatisms"
          key={val.id}
        >
          <div
            className="each-category-automatism"
            id={`f${val.category}`}
          ></div>

          {this.state.data.content.all[parseInt(key)] !== undefined
            ? this.makeHeaderAuto(key)
            : null}

          <div className="section_caracterists">
            <ul className="simbolo_content">
              {this.state.data.content.all[parseInt(key)] !== undefined
                ? this.makeSimbolos(key)
                : null}
            </ul>
          </div>
          <div className="lastPart">
            {this.state.data.content.all !== undefined
              ? this.makeSecondPart(key)
              : null}

            <div className="line"></div>
          </div>
        </div>
      );
    });
    return body;
  };

  render() {
    return (
        <div>
          <p className="phrase_side">
            {this.props.t("views").automatisms.slogan}
          </p>
          {this.state.data.content && this.state.data.content.all !== undefined
              ? this.makebod()
              : null}
        </div>);
  }
}

export default withTranslation()(AutomatismsView);
