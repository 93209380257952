import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { withTranslation } from "react-i18next";

// views
import ProjectsView from "./views/ProjectsView";
import DownloadsView from "./views/DownloadsView";
import HeaderContent from "./HeaderContent";

import "../scss/Jumbostron.scss";

import { ListFilter } from "./FiltersAndOrders";

/***
 * @description
 *
 * Este componente tem como funcionalidade dotar a App de um template de suporte
 * às VIEWS
 * -> PROJECT (list)
 * -> DOWNLOADS
 * Faz ainda a ponte com os FILTROS usados em cada uma destas VIEWS
 *
 */
class Jumbotron extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: null, // idioma em utilização
      data: null, // dados para utilização no compoenente ou relacionados
      headerData: null, // suporte para dados do HEADER
      filter: null, // para execução de acções sobre filtros
      search: null, // para execução de acções sobre search
      order: null, // filtros de ordenação / ordenadores
      selFilter: null, // filter...
      selOrder: null, // asc /desc
      searchExpr: "", // serach expression
    };

    this.t = props.t; // tradutor
    this.i18n = props.i18n.language; // para efeitos de internacionalização

    this.view = undefined; // component view do URI
    this.view = this.props.match.url.split("/");

    // verifica tratar-se de projectos ou downloads

    this.view =
      Array.isArray(this.view) && this.view.includes(this.t("menu").projects[1])
        ? "ProjectsView"
        : this.view.includes(this.t("menu").downloads[1])
        ? "DownloadsView"
        : null;
  }

  /**
   * @description
   *
   */
  componentDidMount() {
    this.t = this.props.t; // p utilização nas acções de tradução
    this.i18n = this.props.i18n;

    let { lang } = this.state;
    lang = this.i18n.language;

    this.setState({ lang });

    // define o URL / URI de pedido de dados à API de acordo com
    // o acesso à APP
    // projetos
    // downloads
  }

  /**
   *
   * @param {*} prevProps
   * @param {*} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    let { lang } = prevState;

    if (lang !== this.i18n.language) {
      lang = this.i18n.language;
      this.setState({ lang });
    }
  }

  /**
   * @description
   * Para execução de acções sobre as STATE vars do component relacionado...
   *
   * @param {*} elemHeaderData
   */

  setParentData = (elemHeaderData) => {
    this.setState(elemHeaderData);
    document.querySelector(".jumbotron-content").scrollIntoView({
      behavior: "smooth",
    });
  };

  render() {
    return (
      <>
        {/* JUMBOTRON HEADER */}
        <div className="jumbotron">
          <div className="container-fluid">
            <HeaderContent
              lang={this.state.lang}
              headerData={this.state.headerData}
            />
          </div>
        </div>
        <div className="row jumbotron-content">
          <div
            className={`col-1 col-lg-10 filter-container ${
              this.view /* .toLowerCase() */
            }`}
          >
            <ListFilter
              t={this.t}
              srchKeyDownHandler={
                this.state.search !== null ? this.state.search.onKeyDown : ""
              }
              match={this.props.match}
              filterOptions={this.state.filter}
              setParentData={this.setParentData}
            />
          </div>

          <Router>
            <Switch>
              <Route
                exact
                path={`/:lang([a-z]{2})/${this.t("menu").projects[1]}`}
                render={(props) => (
                  <ProjectsView
                    {...props}
                    setParentData={this.setParentData}
                    selFilter={this.state.selFilter}
                    searchExpr={this.state.searchExpr}
                  />
                )}
              />
              <Route
                exact
                path={`/:lang([a-z]{2})/${this.t("menu").downloads[1]}`}
                render={(props) => (
                  <DownloadsView
                    {...props}
                    setParentData={this.setParentData}
                    selFilter={this.state.selFilter}
                    searchExpr={this.state.searchExpr}
                  />
                )}
              />
            </Switch>
          </Router>
        </div>
      </>
    );
  }
}

export default withTranslation()(Jumbotron);
