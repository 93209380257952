import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "../scss/AppFooter.scss";
import "../scss/AppMenu.scss";

import SocialMediaIcons from "./SocialMedia";
import FinancingSystems from "./FianacingSystems";
import {
  //AppMenu,
  UniverseMenu,
  StoreAndDownloads,
  Rgpd,
} from "./AppMenu";

import ReactHtmlParser from "react-html-parser";
//import HtmlParser from "react-html-parser";

const AppFooter = (props) => {
  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState();

  useEffect(() => {
    // Actualiza o idioma usado na app... esta var poderá servir como
    // Lifting Up State e por esta via implicar o render dos components
    // child. Neste momento julga-se últil para obter os conteúdos
    // dinâmicos a partir de uma API ou BO
    setLang(i18n.language);
  }, [lang, setLang]);

  var propsFooter = props.navData;

  if (propsFooter && propsFooter !== null && propsFooter !== undefined) {
    /**Link for Products */
    if (
      propsFooter.products &&
      propsFooter.products !== null &&
      propsFooter.products !== undefined
    ) {
      var idCatProd = propsFooter.products[0].id;
      var slugCatProd = propsFooter.products[0].slug;
      var idProd = propsFooter.products[0].offspring[0].id;
      var slugProd = propsFooter.products[0].offspring[0].slug;
      var urlProduct =
        idCatProd +
        "/" +
        slugCatProd +
        "/" +
        idProd +
        "/" +
        slugProd +
        "/#f" +
        idProd;
    }

    /**Link for Services */
    if (
      propsFooter.services &&
      propsFooter.services !== null &&
      propsFooter.services !== undefined
    ) {
      var idServ = propsFooter.services[0].id;
      var slugServ = propsFooter.services[0].slug;
      var urlService = idServ + "/" + slugServ + "/#f" + idServ;
    }

    /**Link for Automatisms */
    if (
      propsFooter.automatisms &&
      propsFooter.automatisms !== null &&
      propsFooter.automatisms !== undefined
    ) {
      var idAuto = propsFooter.automatisms[0].id;
      var slugAuto = propsFooter.automatisms[0].slug;
      var urlAutomatisms = idAuto + "/" + slugAuto;
    }
    /**Link for GreenHouse */
    if (
      propsFooter.ghouse &&
      propsFooter.ghouse !== null &&
      propsFooter.ghouse !== undefined
    ) {
      var idGHouse = propsFooter.ghouse[0].id;
      var slugGHouse = propsFooter.ghouse[0].slug;
      var urlGHouse = idGHouse + "/" + slugGHouse;
    }
  }

  return (
    <footer className="app-footer row">
      <div className="col-12">
        <header>
          <div className="line"></div>
          <img alt="" src="/assets/imgs/prilux.png" className="logo_prilux" />
          <p className="slogan_prilux">
            {ReactHtmlParser(t("views").footer.slogan)}
          </p>
        </header>
      </div>
      <div className="nav-container row">
        <div className="app-footer-nav col-12 col-sm-6 col-lg-3">
          {/* <AppMenu /> */}
          <div className="app-menu">
            <ul>
              <li className="">
                <a href={`/${lang}/${t("menu").services[1]}/${urlService}`}>
                  {t("menu").services[0]}
                </a>
              </li>

              <li className="">
                <a href={`/${lang}/${t("menu").ghouse[1]}/${urlGHouse}`}>
                  {t("menu").ghouse[0]}
                </a>
              </li>

              <li className="">
                <a
                  href={`/${lang}/${
                    t("menu").automatisms[1]
                  }/${urlAutomatisms}`}
                >
                  {t("menu").automatisms[0]}
                </a>
              </li>

              <li className="">
                <a href={`/${lang}/${t("menu").products[1]}/${urlProduct}`}>
                  {t("menu").products[0]}
                </a>
              </li>

              <li className="">
                <a href={`/${lang}/${t("menu").projects[1]}`}>
                  {t("menu").projects[0]}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="app-footer-wwa col-12 col-sm-6 col-lg-3">
          <UniverseMenu
            use="footer"
            navData={props.navData ? props.navData.universe : null}
          />
        </div>
        <div className="app-footer-b2b col-12 col-sm-6 col-lg-2">
          <StoreAndDownloads />
        </div>
        <div className="app-footer-cntct col-12 col-sm-6  col-lg-2">
          <a href={`/${lang}/${t("menu").contacts[1]}`}>
            {t("menu").contacts[0]}
          </a>
          <SocialMediaIcons />
        </div>
        <div className="app-footer-rgpd col-12 col-lg-2">
          <Rgpd />
        </div>
      </div>
      <FinancingSystems />
      <div className="mt-2 f-systems row">
        <ul>
          <li className="" style={{maxWidth: 285}}>
            <a href="/assets/docs/PRILUX_SI_Inov_Prod.pdf" target="_self" rel="noopener noreferrer">
              <img
                  className="img-fluid Compete_2020" src="/assets/imgs/novo_centro2020.png" alt="prr"/></a></li>


        </ul>
      </div>
      <div className="mt-2 f-systems row">
        <ul>
          <li className="col-2">
            <a href="https://recuperarportugal.gov.pt" target="_self" rel="noopener noreferrer">
              <img
                  className="img-fluid Compete_2020" src="/assets/imgs/prr.png" alt="prr"/></a></li>
          <li className="col-2">
            <a href={`/${lang}/${t("menu").funded_projects[1]}`} target="_self" rel="noopener noreferrer">
              <img
                  className="img-fluid Compete_2020" src="/assets/imgs/republica_portuguesa.png" alt="republica_portuguesa"/></a></li>
          <li className="col-2">
            <a href={`/${lang}/${t("menu").funded_projects[1]}`}  target="_self" rel="noopener noreferrer">
              <img
              className="img-fluid Compete_2020" src="/assets/imgs/eu_next_generation.png" alt="eu_next_generation"/></a></li>

        </ul>
      </div>

      <div className="root-div">
        <a href="#root" className="go-top">
          <img className="s2" src="/assets/imgs/Seta-botao-3.png" alt="seta" />
          <img className="s1" src="/assets/imgs/Seta-botao-4.png" alt="seta" />
        </a>
      </div>

    </footer>
  );
};

export default AppFooter;
