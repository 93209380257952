import "../../scss/views/SearchProductView.scss";

import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import RestDataSource from "../../components/webservices/RestDataSource";

import {
  extractElementsFromArrayByIsType,
  insertMediaWithAppAbsPath,
  Configs,
} from "../../Toolbox";

/**
 * @description
 *
 *
 */
class SearchProductView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: null,
      data: {},
      menu: null, // para armazenamento do componentes de navegação...
    };

    this.t = this.props.t; //tradutor
    this.i18n = null;
    this.newMenu = null;
    this.newData = null;
    this.logicLatch = false; //TRINCO LÓGICO - FALSE aberto | TRUE fechado

    this.optionId = undefined; //ID da CATEGORIA dos artigos
    this.optionSlug = undefined; //respectivo slug

    if (
      this.props.match &&
      this.props.match.params &&
      "searchText" in this.props.match.params
    ) {
      this.searchText = this.props.match.params.searchText;
    }

    this.webserver = new RestDataSource(); // para comunicação com a API (REST)
    this.restURI = `product/all_list/ffs`; // para utilização nos pedidos de dados à API / URI do pedido
    this.pages = 0;
  }

  /**
   * @description
   * Para inicialização dos componentes e afins após o seu carregamento no DOM
   */
  componentDidMount() {
    window.sessionStorage.removeItem("stickyBFM");
    this.t = this.props.t; // p utilização nas acções de tradução
    this.i18n = this.props.i18n;

    let { lang } = this.state;
    lang = this.i18n.language;

    this.setState({ lang });

    this.logicLatch = true;
    // executa pedido à API para fabricar o menu lateral esquerdo...
    this.getDataFromAPI(this.makeURI());
  }

  /**
   * @description
   * Verifica se existem alterações nas props e/ou state e executa
   * as necessárias alterações em conformidade, exemplo, quando se
   * altera o idioma do site poderá ser necessário obter novos termos
   * no novo idioma para preenchimento do layout...
   * caso se verifique que os mesmos já existam dever-se-à evitar a
   * utilização deste recurso, isto porque, poderá estar a criar um
   * conjunto de pedidos desnecessários e que eventualmente tornarão
   * esta solução mais lenta
   *
   * @param {object} prevProps
   * @param {object} prevState
   */
  componentDidUpdate(prevProps, prevState) {
    let { lang } = prevState;
    let { menu } = this.state;

    if (
      this.props.match &&
      this.props.match.params &&
      "searchText" in this.props.match.params
    ) {
      this.searchText = this.props.match.params.searchText;
    }

    if (lang !== this.i18n.language) {
      lang = this.i18n.language;

      this.setState({ lang }, () => {
        //idioma alterado, obtem os dados da API no novo idioma
        if (this.logicLatch === false) {
          //fecha o trinco para efeitos de acesso ao pedido de
          //novos conteúdos à API
          this.logicLatch = true;
          //executa o pedido
          this.getDataFromAPI(this.makeURI());
        }
      });
    }


  }

  /**
   * @description
   * link da BD para listagem de categorias
   */
  makeURI() {
    let requestURI = `${this.i18n.language}/${this.restURI}`;

    // verifica se existe optionId e acrescenta-os ao URI
    if (this.searchText !== undefined && this.searchText !== null) {
      requestURI += `${this.searchText}/ppa${this.pages}/ppi12`;
      this.pages++;
    }

    return requestURI;
  }

  /**
   * @description
   * ...
   *
   * @param {*} requestURL
   * @param {*} type
   */
  getDataFromAPI = (requestURL, type) => {

    this.webserver.getData(requestURL, (result, xhr) => {
      this.logicLatch = false;

      if (xhr.status === 200 && "data" in xhr && typeof xhr.data === "object") {
        let { data } = this.state;
        data = result;
        this.newData = data;
        this.setState({ data });
      }
    });
  };

  /**
   * @description: SubCategorias associadas ao click da categoria
   *
   **/
  makeSubCategoryProduct = (x) => {

    return (
      <div className="container-d-coisas">
        <div className="product-list">{this.makeProduct(x)}</div>
      </div>
    );
  };

  /**
   * @description: Listagem de produtos associados a cada subcategoria
   *
   * @param {array} productList -> lista de produtos a apresentar por categoria / FAMILIA
   */
  makeProduct(productsList) {
    let product = null;

    product = productsList.map((val) => {
      // obter imagem do tipo ICON
      let img = extractElementsFromArrayByIsType(val.imgs, "icon");
      // valida resultado obtido e gera o link absoluto da imagem

      img =
        img !== null ? insertMediaWithAppAbsPath(img[0].name, "imgs") : null;

      let alt =
        val.imgs[0] !== null && val.imgs[0] !== undefined
          ? val.imgs[0].alt
          : null;

      let url = null;
      var found = val.ref.split("_", 1);

      if (found !== undefined || found[0] !== "") {
        switch (found[0].toLowerCase()) {
          case "apoio":
            url =
              Configs.base_url +
              this.state.lang +
              "/" +
              this.t("menu").suporte_ghouse[1] +
              "/" +
              val.category[0].parentID[0].id +
              "/" +
              val.category[0].parentID[0].slug +
              "/" +
              val.category[0].id +
              "/" +
              val.category[0].slug;

            break;
          case "produto":
            url =
              Configs.base_url +
              this.state.lang +
              "/" +
              this.t("menu").product_detalhe[1] +
              "/" +
              val.id +
              "/" +
              val.slug;

            break;
          case "automatismos":
            url =
              Configs.base_url +
              this.state.lang +
              "/" +
              this.t("menu").automatisms[1] +
              "/" +
              val.category[0].id +
              "/" +
              val.slug;

            break;
          case "estufaprod":
            if (
              val.category &&
              val.category[0].parentID &&
              val.category[0].parentID &&
              val.category[0].parentID[0].code === "servicos"
            ) {
              url =
                Configs.base_url +
                this.state.lang +
                "/" +
                this.t("menu").ghouse[1] +
                "-" +
                this.t("menu").services[1] +
                "/" +
                val.category[0].parentID[0].id +
                "/" +
                val.category[0].parentID[0].slug +
                "/" +
                val.category[0].id +
                "/" +
                val.category[0].slug +
                "/#f" +
                val.category[0].id;
            } else {
              url =
                Configs.base_url +
                this.state.lang +
                "/" +
                this.t("menu").product_detalhe[1] +
                "/" +
                val.id +
                "/" +
                val.slug;
            }

            break;
          case "estufa":
            url =
              Configs.base_url +
              this.state.lang +
              "/" +
              this.t("menu").ghouse[1] +
              "/" +
              val.category[0].id +
              "/" +
              val.category[0].slug;

            break;
          default:
            url =
              Configs.base_url +
              this.state.lang +
              "/" +
              this.t("menu").product_detalhe[1] +
              "/" +
              val.id +
              "/" +
              val.slug;
        }
      } else {
        found = val.ref;

      }

      return (
        <div
          key={val.id}
          className="card col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"
        >
          <a href={url}>
            <img
              className="card-img-top"
              src={img !== null ? img : null}
              alt={alt !== null ? alt : "produto"}
            />
            <div className="card-body">
              <h1 className="card-text">{val.title}</h1>
            </div>
          </a>
        </div>
      );
    });

    return product;
  }
  getmore() {

    this.webserver.getData(this.makeURI(), (result, xhr) => {
      if (xhr.status === 200 && "data" in xhr && typeof xhr.data === "object") {
        let data = result;
        if (
          data &&
          data.productsList !== undefined &&
          data.productsList !== null &&
          data.productsList.length > 0
        ) {
          var vec3 = this.state.data.productsList.concat(data.productsList);

          data = this.state.data;
          data.productsList = vec3;

          this.setState({ data });

        }

      }
    });
  }

  render() {
    if (this.state.data !== undefined && this.state.data !== null) {
      return (
        <>
          <h6 className="resultTitle">{this.t("withResults")}</h6>
          <div className="body_products">
            <div className="col-12 col-lg-10 familyProducts">
              {this.state.data.productsList ? (
                this.makeSubCategoryProduct(this.state.data.productsList)
              ) : (
                <div> {this.t("noResults")}</div>
              )}
              {this.pages * 12 < parseInt(this.state.data.productsTotal) &&
              this.state.data.productsTotal !== "0" ? (
                <button className="moreButton" onClick={() => this.getmore()}>
                  +
                </button>
              ) : null}
            </div>
          </div>
        </>
      );
    }
  }
}

export default withTranslation()(SearchProductView);
